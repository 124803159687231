import { useEffect, useState } from "react";
import Button from "../../Components/Button";
import Tags from "../../Components/Tags";
import styles from "../../Styles/inputField.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface SubCategoryProps {
  data: any;
  index: number;
  remove: any;
  updateName: any;
  updateTag: any;
  removeImage: any;
  removeTag: any;
  addTag: any;
}

const SubCategory = ({
  data,
  index,
  remove,
  updateName,
  updateTag,
  removeImage,
  removeTag,
  addTag,
}: SubCategoryProps) => {
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState<any>([]);

  console.log("data is ::::: ", data);

  const onchange = (e: any) => {
    setTag(e.target.value);
  };

  const pushTags = () => {
    updateTag((prev: any) => {
      prev[index].product = [...data.product, tags];
      return [...prev];
    });
  };

  useEffect(() => {
    // pushTags();
  }, [tags]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 8,
          border: "1px solid black",
          padding: "12px 8px",
          borderRadius: 8,
        }}
      >
        <Button
          text={"Remove"}
          type={"outlined"}
          name={"remove"}
          clickHandler={remove}
          isIcon=""
          style={{
            padding: "4px 12px",
          }}
        />
        <div className={styles.field_contianer}>
          <label htmlFor="">Title</label>
          <input
            type="text"
            value={data.title}
            placeholder="Title"
            onChange={(e) => updateName(e, index, "title")}
            name="title"
          />
        </div>
        <div className={styles.field_contianer}>
          <label htmlFor="">Sub Image</label>
          {data.subImage.url === "" ? (
            <input
              type="file"
              accept="image"
              onChange={(e) => updateName(e, index, "subImage")}
              name="subImage"
            />
          ) : (
            <div
              style={{
                height: "200px",
                aspectRatio: "9/5",
                background: `url('${data.subImage.url}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
                borderRadius: "10px",
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                color="white"
                style={{
                  position: "absolute",
                  height: "20px",
                  aspectRatio: "1/1",
                  background: "grey",
                  borderRadius: "100%",
                  padding: "4px",
                  left: "calc(100% - 20px)",
                  top: "-10px",
                  cursor: "pointer",
                }}
                onClick={() => removeImage(index)}
              />
            </div>
          )}
        </div>
        <div className={styles.field_contianer}>
          <label htmlFor="">Products</label>
          <input
            type="text"
            value={tag}
            placeholder="Tags"
            onChange={onchange}
            name="product"
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                addTag(index, tag);
                // setTags((prev: any) => {
                //   prev.push(tag);
                //   return [...prev];
                // });
                setTag("");
              }
            }}
          />
          <Tags
            tags={data.product}
            updateTag={(indx: any) => {
              let temp = data.product;
              temp.splice(indx, 1);
              removeTag(index, [...temp]);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SubCategory;
