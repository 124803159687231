import { useNavigate } from "react-router-dom";
import WithTableView from "../../GenericPages/WithTableView";
import { formatDate } from "../../Utils/FormatDate";
import { useState ,useEffect} from "react";
import Loading from "../../Components/Loading";
import callApi from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import axios from "axios";

const Admins = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [adminData,setAdminData]=useState<any>([])

  const getData = () => {
    callApi({
      url: "",
      method: "get",
      header:true,
      type: "admin",
    })
      .then((res) => {
        setAdminData(res.data.data);
        // console.log('admin data',res.data.data);
        setLoading(false);
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleActionClick = (val: any, id: any) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `https://www.ahinternational.online/v1/admin/${id}`,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_AHI")}`,
          },
        })
          .then((res) => {
            getData();
            resolve(true);
          })
          .catch((_e) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Something went wrong!",
      }
    );
  };




  return (
    <>
      <Loading isLoading={loading} />
      <WithTableView
        heading="Admins"
        head={[
          { title: "S.No." },
          { title: "Email" },
          { title: "Role" },
          { title: "Created At" },
          { title: "Updated At" },
          { title: "" },
        ]}
        body={adminData?.map((el: any) => {
            return {
              _id: el._id,
              email: el.email,
              role: el.role,
              createdAt:formatDate(el.createdAt),
              updatedAt:formatDate(el.updatedAt)
            };
          })
        }
        body_keys={["email", "role", "createdAt", "updatedAt", "action:delete"]}
        show_btn_1={true}
        btn1Text="Add new"
        isIcon
        btn1Type="filled"
        onRowClick={(val: any) => {
          navigate(`/admins/update_admin/${val._id}`);
        }}
        onClickHandler={() => {
          navigate("/admins/create");
        }}
        onActionClick={handleActionClick}
      />
    </>
  );
};

export default Admins;
