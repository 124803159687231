export const formatDate = (val: string) => {
    if(val===null){
      return 'not available'
    }
    else{
      let date = val.split("T");
      let dateArr = date[0].split("-");
    
      let year = dateArr[0];
      let month = dateArr[1];
      let day = dateArr[2];
    
      return day + "/" + month + "/" + year;
    }

  
};
