import { useEffect, useState } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import callApi from "../../Apis/callApi";
import axios from "axios";
import Loading from "../../Components/Loading";

const Contacts = () => {
  const [contact, setContact] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const getData = () => {
    callApi({
      url: "getAllContact",
      method: "get",
      type: "contact",
    })
      .then((res) => {
        setLoading(false);
        setContact(res.data.data.contact);
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (val: any, id: any) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `https://www.ahinternational.online/v1/contact/deleteContact/${id}`,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_AHI")}`,
          },
        })
          .then((res) => {
            getData();
            resolve(true);
          })
          .catch((_e) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Something went wrong!",
      }
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <WithTableView
        heading="Contacts"
        head={[
          { title: "S.No." },
          { title: "Title" },
          { title: "First Name" },
          { title: "Company" },
          { title: "Position" },
          { title: "Department" },
          { title: "Email" },
          { title: "" },
        ]}
        body={contact.map((el: any) => {
          return {
            _id: el._id,
            Title: el.title,
            firstName: el.firstName,
            company: el.company,
            position: el.position,
            department: el.department,
            email: el.email,
          };
        })}
        body_keys={[
          "Title",
          "firstName",
          "company",
          "position",
          "department",
          "email",
          "action:delete",
        ]}
        show_btn_1={false}
        btn1Text="Add new"
        btn1Type="filled"
        // onRowClick={(val: any) => {
        //   navigate(`/contacts/update_contact/${val.id}`);
        // }}
        onClickHandler={() => {
          navigate("/contacts/create");
        }}
        onActionClick={handleDelete}
      />
    </>
  );
};

export default Contacts;
