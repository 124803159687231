import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import Tags from "../../../Components/Tags";
import styles from "../../../Styles/inputField.module.css";
import Loading from "../../../Components/Loading";

// interface CreateCareerProps {}

const CreateCareer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    department: "",
    position: "",
    type: "",
    title: "",
    description: "",
    skill: "",
    experience: "",
    qualification: "",
    salary: "",
    noticePeriod: "",
    location: "",
    workingDays: "",
    shiftTime: "",
    benefits: "",
    hiringProcess: "",
    numberOpening: "",
  });

  const {
    department,
    position,
    type,
    title,
    description,
    skill,
    experience,
    qualification,
    salary,
    noticePeriod,
    location,
    workingDays,
    shiftTime,
    benefits,
    hiringProcess,
    numberOpening,
  } = values;

  useEffect(() => {
    if (id) {
      getData();
      setLoading(true);
    }
  }, []);

  const getData = () => {
    callApi({
      method: "get",
      url: `getJobById/${id}`,
      type: "career",
     
    })
      .then((res: any) => {
        
        console.log('career page update',res);
        
          setValues({
            department: res.data.data.department,
            position: res.data.data.position,
            type: res.data.data.type,
            title: res.data.data.title,
            description: res.data.data.description,
            skill: "",
            experience: res.data.data.experience,
            qualification: res.data.data.qualification,
            salary: res.data.data.salary,
            noticePeriod: res.data.data.noticePeriod,
            location: res.data.data.location,
            workingDays: res.data.data.workingDays,
            shiftTime: res.data.data.shiftTime,
            benefits: res.data.data.benefits,
            hiringProcess: res.data.data.hiringProcess,
            numberOpening: res.data.data.numberOpening,
          });
          setTags(res.data.data.skills)
          setLoading(false);
        
      })
      .catch((error) => {
        setLoading(false);
        console.log('error fetch get job by id',error)
      });
  };

  const onChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const [tags, setTags] = useState<any>([]);

  const handleClick = (e: any) => {
    e.preventDefault();

    if (e.target.name === "discard") {
      setValues({
        department: "",
        position: "",
        type: "",
        title: "",
        description: "",
        skill: "",
        experience: "",
        qualification: "",
        salary: "",
        noticePeriod: "",
        location: "",
        workingDays: "",
        shiftTime: "",
        benefits: "",
        hiringProcess: "",
        numberOpening: "",
      });
      navigate("/career");
    } else if (e.target.name === "create") {

      setLoading(true);
      if(id){
        updateData();
      }
    else{
      callApi({
        url: "addJob",
        method: "post",
        type: "career",
        data: {
          ...values,
          skill: undefined,
          skills: tags,
          experience: Number(values.experience),
          salary: Number(values.salary),
          noticePeriod: Number(values.noticePeriod),
          workingDays: Number(values.workingDays),
          numberOpening: Number(values.numberOpening),
        },
        header: true,
      })
        .then((res: any) => {
          setValues({
            department: "",
            position: "",
            type: "",
            title: "",
            description: "",
            skill: "",
            experience: "",
            qualification: "",
            salary: "",
            noticePeriod: "",
            location: "",
            workingDays: "",
            shiftTime: "",
            benefits: "",
            hiringProcess: "",
            numberOpening: "",
          });
          if (res.status === 201) {
            toast.success("Successfully Created!");
            navigate("/career");
            setLoading(false);
          }
        })
        .catch((_e) => {
          toast.error(_e.response.data.error);
          setLoading(false);
        });
      }
    }
  };


  const updateData=()=>{
    callApi({
      url: "updateJob/" + id,
      method: "patch",
      type: "career",
      data: {
        ...values,
        skill: undefined,
        skills: tags,
        experience: Number(values.experience),
        salary: Number(values.salary),
        noticePeriod: Number(values.noticePeriod),
        workingDays: Number(values.workingDays),
        numberOpening: Number(values.numberOpening),
      },
      header: true,
    })
      .then((res) => {
        setLoading(false);
        navigate("/career");
        toast.success("Successfully Updated!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      });
  }

  return (
    <>
      <Loading isLoading={loading} />
      <Content>
        <ContentHead
          title="Create Job"
          showBtn1={true}
          btn1Name="create"
          btn1type="filled"
          btn1Text="Create"
          onClickHandler={handleClick}
          showBtn2={true}
          btn2Name="discard"
          btn2Text="Discard"
          btn2type="outlined"
        />
        <ContentBody>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              rowGap: 12,
              columnGap: 12,
              gridAutoRows: "auto",
            }}
          >
            <InputField
              isLabel={true}
              value={department}
              lable={"Department"}
              type={"text"}
              isRequired={false}
              placeholder={"Department"}
              name={"department"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={position}
              lable={"Position"}
              type={"text"}
              isRequired={false}
              placeholder={"Position"}
              name={"position"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={type}
              lable={"Type"}
              type={"text"}
              isRequired={false}
              placeholder={"Type"}
              name={"type"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={title}
              lable={"Title"}
              type={"text"}
              isRequired={false}
              placeholder={"Title"}
              name={"title"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={description}
              lable={"Description"}
              type={"text"}
              isRequired={false}
              placeholder={"Description"}
              name={"description"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <div className={styles.field_contianer}>
              <label htmlFor="">Skills</label>
              <input
                type="text"
                value={skill}
                placeholder="Skills"
                onChange={onChange}
                name="skill"
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    setTags((prev: any) => [...prev, skill]);
                    setValues({ ...values, skill: "" });
                  }
                }}
              />
              <Tags tags={tags} updateTag={setTags} />
            </div>
            <InputField
              isLabel={true}
              value={experience}
              lable={"Experience ( in years )"}
              type={"number"}
              isRequired={false}
              placeholder={"Experience"}
              name={"experience"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={qualification}
              lable={"Qualification"}
              type={"text"}
              isRequired={false}
              placeholder={"Qualification"}
              name={"qualification"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={salary}
              lable={"Salary"}
              type={"number"}
              isRequired={false}
              placeholder={"Salary"}
              name={"salary"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={noticePeriod}
              lable={"Notice Period"}
              type={"number"}
              isRequired={false}
              placeholder={"Notice Period"}
              name={"noticePeriod"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={location}
              lable={"Location"}
              type={"text"}
              isRequired={false}
              placeholder={"Location"}
              name={"location"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={workingDays}
              lable={"Working Days"}
              type={"number"}
              isRequired={false}
              placeholder={"Working Days"}
              name={"workingDays"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={shiftTime}
              lable={"ShiftTime"}
              type={"text"}
              isRequired={false}
              placeholder={"Shift Time"}
              name={"shiftTime"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={benefits}
              lable={"Benefits"}
              type={"text"}
              isRequired={false}
              placeholder={"Benefits"}
              name={"benefits"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={hiringProcess}
              lable={"Hiring Process"}
              type={"text"}
              isRequired={false}
              placeholder={"Hiring Process"}
              name={"hiringProcess"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={numberOpening}
              lable={"Number of Opening"}
              type={"number"}
              isRequired={false}
              placeholder={"Number of Opening"}
              name={"numberOpening"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default CreateCareer;
