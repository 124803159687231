import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import Tags from "../../../Components/Tags";
import styles from "../../../Styles/inputField.module.css";
import UploadImage from "../../../Utils/UploadImage";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import Loading from "../../../Components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { brotliDecompress } from "zlib";

// interface CreateClientProps {}

const CreateClient = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [isLogoChanged, setIsLogoChanged] = useState(false);
  const [tags, setTags] = useState<any>([]);
  const [image, setImage]: any = useState(null);
  const [image2, setImage2]: any = useState(null);
  const [values, setValues]: any = useState({
    clientName: "",
    clientBrand: "",
    clientCity: "",
    clientCountry: "",
    solution: "",
    // logoUrl: "",
    // imageOne: "",
    // priority: "",
  });
  const {
    clientName,
    clientBrand,
    clientCity,
    clientCountry,
    solution,
    // logoUrl,
    // imageOne,
    // priority,
  } = values;

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  const getData = () => {
    callApi({
      url: "getClientById/" + id,
      method: "get",
      type: "client",
    })
      .then((res) => {
        console.log("update client api called", res);
        setValues({
          clientName: res.data.data.clientName,
          clientBrand: res.data.data.clientBrand,
          clientCity: res.data.data.clientCity,
          clientCountry: res.data.data.clientCountry,
          // solution: res.data.data.solution,

          // priority: res.data.data.priority,
        });
        setImage(res.data.data.logoUrl);
        setImage2(res.data.data.imageOne);
        setTags(res.data.data.solution);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong while fetching data!");
      });
  };

  const validate = () => {
    // if (image2 === null) {
    //   toast.error("LogoUrl is required!");
    //   return false;
    // }
    // if (image === null) {
    //   toast.error("Image is required!");
    //   return false;
    // }

    if (!clientName) {
      toast.error("Name is required!");
      return false;
    }
    // if (!clientBrand) {
    //   toast.error("Brand is required!");
    //   return false;
    // }
    // if (!clientCity) {
    //   toast.error("Name is required!");
    //   return false;
    // }

    return true;
  };

  const handleimage = (e: any) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleimage2 = (e: any) => {
    const file = e.target.files[0];
    // console.log(file);

    setImage2(file);
  };

  const onChange = (e: any) => {
    let name = e.target.name;

    let value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    if (e.target.name === "discard") {
      setValues({
        clientName: "",
        clientBrand: "",
        clientCity: "",
        clientCountry: "",
        solution: "",
        logoUrl: "",
        imageOne: "",
        priority: "",
      });
      navigate("/clients");
    } else if (e.target.name === "create") {
      setLoading(true);
      if (id) {
        updateData();
      } else {
        console.log("image", image);
        console.log(image2);
        setLoading(true);
        let name = getExtendedFileName(image);
        let name2 = getExtendedFileName(image2);

        UploadImage({ file: image, name: name })
          .then(() => {
            UploadImage({ file: image2, name: name2 })
              .then(() => {
                callApi({
                  url: "addClient",
                  method: "post",
                  type: "client",
                  data: {
                    ...values,
                    solution: tags,
                    logoUrl: name,
                    imageOne: name2,
                  },
                  header: true,
                })
                  .then((_res: any) => {
                    setValues({
                      clientName: "",
                      clientBrand: "",
                      clientCity: "",
                      clientCountry: "",
                      solution: "",
                      logoUrl: "",
                      imageOne: "",
                      priority: "",
                    });
                    toast.success("Successfully Created!");
                    setLoading(false);
                  })
                  .catch((_e) => {
                    toast.error("Something went wrong!");
                    setLoading(false);
                  });
              })
              .catch((e) => {
                toast.error("Something went wrong!");
                setLoading(false);
              });
          })
          .catch((e) => {
            toast.error("Something went wrong!");
            setLoading(false);
          });
      }
    }
  };

  const updateData = () => {
    if (isImageChanged && isLogoChanged) {
      // upload image and attachment
      let name = getExtendedFileName(image);
      let name2 = getExtendedFileName(image2);

      UploadImage({
        file: image,
        name: name,
      })
        .then(() => {
          UploadImage({
            file: image2,
            name: name2,
          })
            .then(() => {
              callApi({
                url: "updateClient/" + id,
                method: "patch",
                type: "client",
                data: {
                  ...values,
                  solution: tags,
                  logoUrl: name,
                  imageOne: name2,
                },
                header: true,
              }).then(() => {
                setLoading(false);
                navigate("/clients");
                toast.success("Successfully Updated!");
              });
            })
            .catch(() => {
              setLoading(false);
              toast.error("Something went wrong!");
            });
        })
        .catch(() => {
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }

    if (isImageChanged && !isLogoChanged) {
      // upload image and data without attachment'
      console.log("image changed but not logoutl");
      let name = getExtendedFileName(image2);

      UploadImage({
        file: image2,
        name: name,
      })
        .then(() => {
          callApi({
            url: "updateClient/" + id,
            method: "patch",
            type: "client",
            data: {
              ...values,
              solution: tags,
              logoUrl: image,
              imageOne: name,
            },
            header: true,
          }).then(() => {
            setLoading(false);
            toast.success("Successfully Created!");
            navigate("/clients");
          });
        })
        .catch(() => {});
    }

    if (isLogoChanged && !isImageChanged) {
      // upload attachment and data without image
      console.log("logourl changed image not changed");
      let filename = getExtendedFileName(image);

      UploadImage({
        file: image,
        name: filename,
      })
        .then(() => {
          callApi({
            url: "updateClient/" + id,
            method: "patch",
            type: "client",
            data: {
              ...values,
              solution: tags,
              logoUrl: filename,
              imageOne: image2,
            },
            header: true,
          })
            .then(() => {
              setLoading(false);
              toast.success("Successfully Created!");
              navigate("/clients");
            })
            .catch(() => {
              setLoading(false);
              toast.error("Something went wrong!");
            });
        })
        .catch(() => {
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }

    if (!isLogoChanged && !isImageChanged) {
      // upload data without image and attachment
      callApi({
        url: "updateClient/" + id,
        method: "patch",
        type: "client",
        data: {
          ...values,
          solution: tags,
          logoUrl: image,
          imageOne: image2,
        },
        header: true,
      })
        .then((res) => {
          setLoading(false);
          navigate("/clients");
          toast.success("Successfully Updated!");
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Content>
        <ContentHead
          title="Create Client"
          showBtn1={true}
          btn1Name="create"
          btn1type="filled"
          btn1Text="Save"
          onClickHandler={handleClick}
          showBtn2={true}
          btn2Name="discard"
          btn2Text="Discard"
          btn2type="outlined"
        />
        <ContentBody>
          <div
            style={{
              display: "grid",
              rowGap: 12,
              gridAutoRows: "auto",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "3vw",
                // border:"1px solid red"
              }}
            >
              <div>
                <div className={styles.field_contianer}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      marginBottom: "5px",
                    }}
                  >
                    Image
                  </p>
                  {image === null ? (
                    <input
                      type="file"
                      id="iconUrl"
                      name="logoUrl"
                      accept="image"
                      onChange={(e) => {
                        // onChange(e);
                        handleimage(e);
                      }}
                    />
                  ) : (
                    <div
                      className={styles.show_image}
                      style={{
                        backgroundImage: id
                          ? isLogoChanged
                            ? `url('${
                                typeof image === "object"
                                  ? URL.createObjectURL(image)
                                  : ""
                              }')`
                            : `url('${process.env.REACT_APP_IMAGE_LINK}/${image}')`
                          : `url('${
                              typeof image === "object"
                                ? URL.createObjectURL(image)
                                : ""
                            }')`,
                        width: "500px",
                        aspectRatio: "16/9",
                        borderRadius: "10px",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "rgba(0,0,0,.05)",
                        position: "relative",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faClose}
                        color="white"
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          height: "20px",
                          width: "20px",
                          background: "rgba(0,0,0,1)",
                          borderRadius: "50%",
                          padding: "5px",
                          transform: "translate(40%,-40%)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setImage(null);
                          setIsLogoChanged(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={styles.field_contianer}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      marginBottom: "5px",
                    }}
                  >
                    Image
                  </p>
                  {image2 === null ? (
                    <input
                      type="file"
                      id="imageOne"
                      name="imageOne"
                      accept="image"
                      onChange={(e) => {
                        // onChange(e);
                        handleimage2(e);
                      }}
                    />
                  ) : (
                    <div
                      className={styles.show_image}
                      style={{
                        backgroundImage: id
                          ? isImageChanged
                            ? `url('${
                                typeof image2 === "object"
                                  ? URL.createObjectURL(image2)
                                  : ""
                              }')`
                            : `url('${process.env.REACT_APP_IMAGE_LINK}/${image2}')`
                          : `url('${
                              typeof image2 === "object"
                                ? URL.createObjectURL(image2)
                                : ""
                            }')`,
                        width: "500px",
                        aspectRatio: "16/9",
                        borderRadius: "10px",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "rgba(0,0,0,.05)",
                        position: "relative",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faClose}
                        color="white"
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          height: "20px",
                          width: "20px",
                          background: "rgba(0,0,0,1)",
                          borderRadius: "50%",
                          padding: "5px",
                          transform: "translate(40%,-40%)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setImage2(null);
                          setIsImageChanged(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <InputField
              isLabel={false}
              value={clientName}
              lable={"Client Name"}
              type={"text"}
              isRequired={false}
              placeholder={"Client Name"}
              name={"clientName"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={clientBrand}
              lable={"Client Brand"}
              type={"text"}
              isRequired={false}
              placeholder={"Client Brand"}
              name={"clientBrand"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={clientCity}
              lable={"Client City"}
              type={"text"}
              isRequired={false}
              placeholder={"Client City"}
              name={"clientCity"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={clientCountry}
              lable={"Client Country"}
              type={"text"}
              isRequired={false}
              placeholder={"Client Country"}
              name={"clientCountry"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <div className={styles.field_contianer}>
              <label htmlFor="">Solutions</label>
              <input
                type="text"
                value={solution}
                placeholder="Solutions"
                onChange={onChange}
                name="solution"
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    setTags((prev: any) => [...prev, solution]);
                    setValues({ ...values, solution: "" });
                  }
                }}
              />
              <Tags tags={tags} updateTag={setTags} />
            </div>
            {/* <InputField
              isLabel={true}
              value={priority}
              lable={"Priority"}
              type={"select"}
              isRequired={false}
              placeholder={"Priority"}
              name={"priority"}
              onChange={onChange}
              options={[
                { title: "High", value: "high" },
                { title: "Medium", value: "medium" },
                { title: "Low", value: "low" },
              ]}
              accessText={"title"}
              accessValue={"value"}
            /> */}
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default CreateClient;
