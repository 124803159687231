import { useEffect, useState } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import callApi from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../Utils/FormatDate";
import axios from "axios";
import Loading from "../../Components/Loading";

// interface ContentProps {}

const Content = () => {
  const [content, setContent] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getData = () => {
    callApi({
      url: "getAllContent",
      method: "get",
      type: "content",
    })
      .then((res) => {
        console.log(res.data.data.content);
        setContent(res.data.data.content);
        setLoading(false);
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleActionClick = (val: any, id: any) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `https://www.ahinternational.online/v1/content/deleteContent/${id}`,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_AHI")}`,
          },
        })
          .then((res) => {
            getData();
            resolve(true);
          })
          .catch((_e) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Something went wrong!",
      }
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <WithTableView
        heading="Content"
        head={[
          { title: "S.No." },
          { title: "Title" },
          { title: "Type" },
          { title: "Category" },
          { title: "Updated At" },
          { title: "" },
        ]}
        body={content.map((el: any) => {
          return {
            _id: el._id,
            UpdatedAt: formatDate(el.updatedAt),
            Title: el.title,
            Type: el.type,
            Category: el.category,
            // CreatedAt: el.createdAt,
          };
        })}
        body_keys={["Title", "Type", "Category", "UpdatedAt", "action:delete"]}
        show_btn_1={true}
        btn1Text="Add new"
        btn1Type="filled"
        onRowClick={(val: any) => {
          navigate(`/content/update_content/${val._id}`);
        }}
        onClickHandler={() => {
          navigate("/content/create");
        }}
        onActionClick={handleActionClick}
      />
    </>
  );
};

export default Content;
