import React from "react";
import styles from "../../Styles/applayout.module.css";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

class AppLayout extends React.Component<any> {
	render() {
		return (
			<div className={styles.applayout_container}>
				{/* sidebar */}
				<Sidebar />

				<div className={styles.sidebar_and_content_container}>
					{/* navbar */}
					<Navbar />
					{/* children screen */}
					<div className={styles.content_container}>{this.props.children}</div>
				</div>
			</div>
		);
	}
}

export default AppLayout;
