import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import Tags from "../../../Components/Tags";
import styles from "../../../Styles/inputField.module.css";
import UploadImage from "../../../Utils/UploadImage";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import Loading from "../../../Components/Loading";
import CreateClient from "../createClient/Index";

// interface UpdateClientProps {}

// const UpdateClient = () => {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const [loading, setLoading] = useState(false);

//   const [values, setValues] = useState({
//     clientName: "",
//     clientBrand: "",
//     clientCity: "",
//     clientCountry: "",
//     solution: "",
//     logoUrl: "",
//     imageOne: "",
//     priority: "",
//   });

//   const [tags, setTags] = useState<any>([]);

//   const {
//     clientName,
//     clientBrand,
//     clientCity,
//     clientCountry,
//     solution,
//     logoUrl,
//     imageOne,
//     priority,
//   } = values;

//   const onChange = (e: any) => {
//     let name = e.target.name;
//     let value =
//       name === "logoUrl" || name === "imageOne"
//         ? e.target.files[0]
//         : e.target.value;
//     setValues({ ...values, [name]: value });
//   };

//   const handleClick = (e: any) => {
//     e.preventDefault();
//     if (e.target.name === "discard") {
//       setValues({
//         clientName: "",
//         clientBrand: "",
//         clientCity: "",
//         clientCountry: "",
//         solution: "",
//         logoUrl: "",
//         imageOne: "",
//         priority: "",
//       });
//       navigate("/clients");
//     } else if (e.target.name === "update") {
//       UploadImage({ file: logoUrl, name: getExtendedFileName(logoUrl) })
//         .then(() => {
//           UploadImage({ file: imageOne, name: getExtendedFileName(imageOne) })
//             .then(() => {
//               callApi({
//                 url: `updateClient/${id}`,
//                 method: "patch",
//                 type: "client",
//                 data: {
//                   ...values,
//                   solution: tags,
//                   logoUrl: getExtendedFileName(logoUrl),
//                   imageOne: getExtendedFileName(imageOne),
//                 },
//                 header: true,
//               })
//                 .then((_res: any) => {
//                   setValues({
//                     clientName: "",
//                     clientBrand: "",
//                     clientCity: "",
//                     clientCountry: "",
//                     solution: "",
//                     logoUrl: "",
//                     imageOne: "",
//                     priority: "",
//                   });
//                   toast.success("Successfully Updated!");
//                 })
//                 .catch((_e) => {
//                   toast.error("Something went wrong!");
//                 });
//             })
//             .catch((e) => toast.error("Something went wrong!"));
//         })
//         .catch((e) => toast.error("Something went wrong!"));
//     }
//   };

//   return (
//     <>
//       <Loading isLoading={loading} />
//       <Content>
//         <ContentHead
//           title="Update Client"
//           showBtn1={true}
//           btn1Name="update"
//           btn1type="filled"
//           btn1Text="Update"
//           onClickHandler={handleClick}
//           showBtn2={true}
//           btn2Name="discard"
//           btn2Text="Discard"
//           btn2type="outlined"
//         />
//         <ContentBody>
//           <div
//             style={{
//               display: "grid",
//               rowGap: 12,
//               gridAutoRows: "auto",
//             }}
//           >
//             <InputField
//               isLabel={false}
//               value={clientName}
//               lable={"Client Name"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Client Name"}
//               name={"clientName"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={clientBrand}
//               lable={"Client Brand"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Client Brand"}
//               name={"clientBrand"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={clientCity}
//               lable={"Client City"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Client City"}
//               name={"clientCity"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={clientCountry}
//               lable={"Client Country"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Client Country"}
//               name={"clientCountry"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <div className={styles.field_contianer}>
//               <label htmlFor="">Solutions</label>
//               <input
//                 type="text"
//                 value={solution}
//                 placeholder="Solutions"
//                 onChange={onChange}
//                 name="solution"
//                 onKeyDown={(e: any) => {
//                   if (e.key === "Enter") {
//                     setTags((prev: any) => [...prev, solution]);
//                     setValues({ ...values, solution: "" });
//                   }
//                 }}
//               />
//               <Tags tags={tags} updateTag={setTags} />
//             </div>
//             <InputField
//               isLabel={true}
//               value={priority}
//               lable={"Priority"}
//               type={"select"}
//               isRequired={false}
//               placeholder={"Priority"}
//               name={"priority"}
//               onChange={onChange}
//               options={[
//                 { title: "High", value: "high" },
//                 { title: "Medium", value: "medium" },
//                 { title: "Low", value: "low" },
//               ]}
//               accessText={"title"}
//               accessValue={"value"}
//             />
//             <div className={styles.field_contianer}>
//               <label style={{ fontWeight: 500 }} htmlFor="">
//                 Logo
//               </label>
//               <input
//                 type="file"
//                 name="logoUrl"
//                 accept="image"
//                 onChange={onChange}
//               />
//             </div>
//             <div className={styles.field_contianer}>
//               <label style={{ fontWeight: 500 }} htmlFor="">
//                 Image
//               </label>
//               <input
//                 type="file"
//                 name="imageOne"
//                 accept="image"
//                 onChange={onChange}
//               />
//             </div>
//           </div>
//         </ContentBody>
//       </Content>
//     </>
//   );
// };

const UpdateClient = () => {
  return <CreateClient />;
};

export default UpdateClient;
