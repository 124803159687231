import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import callApi from "../../../Apis/callApi";
import toast from "react-hot-toast";
import styles from "../../../Styles/inputField.module.css";
import Tags from "../../../Components/Tags";
import CreateCareer from "../createCareer/Index";

// interface UpdateCareerProps {}

// const UpdateCareer = () => {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const [loading, setLoading] = useState(true);

//   const [values, setValues] = useState({
//     department: "",
//     position: "",
//     type: "",
//     title: "",
//     description: "",
//     skill: "",
//     experience: "",
//     qualification: "",
//     salary: "",
//     noticePeriod: "",
//     location: "",
//     workingDays: "",
//     shiftTime: "",
//     benefits: "",
//     hiringProcess: "",
//     numberOpening: "",
//   });

//   const {
//     department,
//     position,
//     type,
//     title,
//     description,
//     skill,
//     experience,
//     qualification,
//     salary,
//     noticePeriod,
//     location,
//     workingDays,
//     shiftTime,
//     benefits,
//     hiringProcess,
//     numberOpening,
//   } = values;

//   const onChange = (e: any) => {
//     let name = e.target.name;
//     let value = e.target.value;
//     setValues({ ...values, [name]: value });
//   };

//   const [tags, setTags] = useState<any>([]);

//   const handleClick = (e: any) => {
//     e.preventDefault();
//     if (e.target.name === "discard") {
//       setValues({
//         department: "",
//         position: "",
//         type: "",
//         title: "",
//         description: "",
//         skill: "",
//         experience: "",
//         qualification: "",
//         salary: "",
//         noticePeriod: "",
//         location: "",
//         workingDays: "",
//         shiftTime: "",
//         benefits: "",
//         hiringProcess: "",
//         numberOpening: "",
//       });
//       navigate("/career");
//     } else if (e.target.name === "update") {
//       callApi({
//         url: `updateJob/${id}`,
//         method: "patch",
//         type: "career",
//         data: { ...values, skill: undefined, skills: tags },
//         header: true,
//       })
//         .then((res: any) => {
//           setValues({
//             department: "",
//             position: "",
//             type: "",
//             title: "",
//             description: "",
//             skill: "",
//             experience: "",
//             qualification: "",
//             salary: "",
//             noticePeriod: "",
//             location: "",
//             workingDays: "",
//             shiftTime: "",
//             benefits: "",
//             hiringProcess: "",
//             numberOpening: "",
//           });
//           toast.success("Successfully Updated!");
//         })
//         .catch((_e) => {
//           toast.error("Something went wrong!");
//         });
//     }
//   };

//   return (
//     <>
//       <Content>
//         <ContentHead
//           title="Update Job"
//           showBtn1={true}
//           btn1Name="update"
//           btn1type="filled"
//           btn1Text="Update"
//           onClickHandler={handleClick}
//           showBtn2={true}
//           btn2Name="discard"
//           btn2Text="Discard"
//           btn2type="outlined"
//         />
//         <ContentBody>
//           <div
//             style={{
//               display: "grid",
//               rowGap: 12,
//               gridAutoRows: "auto",
//             }}
//           >
//             <InputField
//               isLabel={false}
//               value={department}
//               lable={"Department"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Department"}
//               name={"department"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={position}
//               lable={"Position"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Position"}
//               name={"position"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={type}
//               lable={"Type"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Type"}
//               name={"type"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={title}
//               lable={"Title"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Title"}
//               name={"title"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={description}
//               lable={"Description"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Description"}
//               name={"description"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <div className={styles.field_contianer}>
//               <label htmlFor="">Skills</label>
//               <input
//                 type="text"
//                 value={skill}
//                 placeholder="Skills"
//                 onChange={onChange}
//                 name="skill"
//                 onKeyDown={(e: any) => {
//                   if (e.key === "Enter") {
//                     setTags((prev: any) => [...prev, skill]);
//                     setValues({ ...values, skill: "" });
//                   }
//                 }}
//               />
//               <Tags tags={tags} updateTag={setTags} />
//             </div>
//             <InputField
//               isLabel={false}
//               value={experience}
//               lable={"Experience"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Experience"}
//               name={"experience"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={qualification}
//               lable={"Qualification"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Qualification"}
//               name={"qualification"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={salary}
//               lable={"Salary"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Salary"}
//               name={"salary"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={noticePeriod}
//               lable={"Notice Period"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Notice Period"}
//               name={"noticePeriod"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={location}
//               lable={"Location"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Location"}
//               name={"location"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={workingDays}
//               lable={"Working Days"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Working Days"}
//               name={"workingDays"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={shiftTime}
//               lable={"ShiftTime"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Shift Time"}
//               name={"shiftTime"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={benefits}
//               lable={"Benefits"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Benefits"}
//               name={"benefits"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={hiringProcess}
//               lable={"Hiring Process"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Hiring Process"}
//               name={"hiringProcess"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={numberOpening}
//               lable={"Number of Opening"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Number of Opening"}
//               name={"numberOpening"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//           </div>
//         </ContentBody>
//       </Content>
//     </>
//   );
// };

const UpdateCareer = () => {
  return <CreateCareer />;
};

export default UpdateCareer;
