import styles from "../../Styles/table.module.css";
import { ReactComponent as Archive } from "../../Assets/icons/archive.svg";
import { ReactComponent as UnArchive } from "../../Assets/icons/unarchive.svg";
import { ReactComponent as Trash } from "../../Assets/icons/trash.svg";
import { ReactComponent as Tick } from "../../Assets/icons/tick.svg";
import { ReactComponent as Cross } from "../../Assets/icons/cross.svg";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faTrash } from "@fortawesome/free-solid-svg-icons";

type props = {
  head: { title: string }[];
  body: any[];
  onRowClick?: any;
  body_keys: string[];
  number?: boolean;
  action_list?: string[];
  action_handler?: any;
  onActionClick?: any;
  onRemarksSave?: any;
  handleDropdownUpdate?: any;
};

const Table = (props: props) => {
  console.log("Table Rendered.");
  const HandleRemarksPart = (data: any) => {
    const [editMode, setEditMode] = useState(false);
    const [remark, setRemark] = useState(data.remarks);
    // const [re]

    return (
      <>
        {editMode ? (
          <div style={{ display: "flex", gap: "5px" }}>
            <textarea
              style={{
                width: "100%",
                height: "100px",
                padding: "5px",
                borderRadius: "10px",
                border: "1px solid grey",
              }}
              value={remark}
              onChange={(event) => {
                setRemark(event.target.value);
              }}
            />
            <div
              style={{
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <button
                onClick={() => {
                  setEditMode(false);
                  props.onRemarksSave(data, remark);
                }}
                style={{
                  width: "35px",
                  height: "35px",
                  background: "green",
                  border: "none",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Tick style={{ color: "white" }} />
              </button>
              <button
                onClick={() => {
                  setEditMode(false);
                  setRemark(data.remarks);
                }}
                style={{
                  width: "35px",
                  height: "35px",
                  background: "red",
                  border: "none",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Cross style={{ fill: "white", height: "20px" }} />
              </button>
            </div>
          </div>
        ) : (
          <p
            className={styles.remarks}
            onClick={() => {
              setEditMode(true);
            }}
          >
            {remark}
          </p>
        )}
      </>
    );
  };

  const HandleActionValue = (
    val: string,
    data: any,
    index: any,
    onActionClick: any
  ) => {
    let action_list = val.split(":")[1].split(",");
    let component_list = [];

    //checkForDelete
    if (action_list.indexOf("delete") !== -1) {
      component_list.push(
        <abbr
          key={index + "-delete"}
          title="delete"
          // data-name="delete"
          style={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {}}
        >
          <div
            style={{
              display: "inline-flex",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              color={"red"}
              style={{ fontSize: "1.2rem", zIndex: 10, cursor: "pointer" }}
              onClick={() => {
                props.onActionClick("delete", data?._id);
              }}
            />
          </div>
        </abbr>
      );
    }

    return <div style={{ display: "flex", gap: "10px" }}>{component_list}</div>;
  };

  return (
    <div className={styles.table_container} >
      <table className={styles.table} style={{ overflow: "auto" }}>
        <thead>
          <tr>
            {props.head.map((val, index) => {
              return (
                <th align="center" key={index}>
                  {val.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody >
          {props.body.map((val, index) => {
            return (
              <tr key={index}  >
                {props?.number && (
                  <td
                    onClick={() => {
                      if (props?.onRowClick) {
                        props.onRowClick(val);
                      }
                    }}
                    align="center"
                    style={
                      props?.onRowClick
                        ? { cursor: "pointer" }
                        : { cursor: "default" }
                      
                    }
                  >
                    {index + 1}.
                  </td>
                )}
                {props.body_keys.map((valll: any, index) => {
                  return (
                    <td 
                      key={index}
                      onClick={() => {
                        if (props?.onRowClick) {
                          props.onRowClick(val);
                        }
                      }}
                      align="center"
                      style={
                        props?.onRowClick
                          ? { cursor: "pointer" }
                          : { cursor: "default" }
                          
                      }
                      onClickCapture={(event) => {
                        if (valll === "action") {
                          event.stopPropagation();
                          props.action_handler(val._id);
                        }
                      }}
                    >
                      {valll.indexOf("action") !== -1 ? (
                        <div
                          onClick={(event: any) => {
                            event.stopPropagation();
                            console.log(event);
                            console.log(event.target.dataset.name);
                            // props.onActionClick(
                            //   event.target.dataset.name,
                            //   val._id
                            // );
                          }}
                          style={{
                            // border: "1px solid black",
                            width: "fit-content",
                            display: "flex",
                          }}
                        >
                          {HandleActionValue(
                            valll,
                            val,
                            index,
                            props.onActionClick
                          )}
                        </div>
                      ) : valll === "remarks" ? (
                        HandleRemarksPart(val)
                      ) : valll.indexOf("status") !== -1 &&
                        valll.indexOf("dropdown") !== -1 ? (
                        <select
                          value={val.status}
                          style={{ padding: "5px" }}
                          onChange={(event: any) => {
                            props.handleDropdownUpdate(val, event);
                          }}
                        >
                          <option value={"lead"}>Lead</option>
                          <option value={"open"}>Open</option>
                          <option value={"closed"}>Closed</option>
                        </select>
                      ) : valll.indexOf("status") !== -1 &&
                        val.status === "live" ? (
                        <p style={{ fontWeight: 500, color: "green" }}>live</p>
                      ) : val[valll] === true ? (
                        "True"
                      ) : val[valll] === false ? (
                        "False"
                      ) : (
                        val[valll]
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
