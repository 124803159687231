import React from "react";
import { Outlet } from "react-router-dom";
import withRouter from "../Components/withRouter";
import AppLayout from "../Components/AppLayout/AppLayout";
import Login from "../Pages/Login";

type props = {
  login: boolean;
};

class App extends React.Component<any, props> {
  state = {
    login: false,
  };

  updateLogin = () => {
    this.setState({ login: true });
  };

  componentDidMount(): void {
    if (localStorage.getItem("token_AHI")) {
      this.setState({ login: true });
    } else {
      this.setState({ login: false });
    }
  }

  render() {
    return (
      <>
        {!this.state.login ? (
          <Login logIn={this.updateLogin} />
        ) : (
          <AppLayout>
            <Outlet />
          </AppLayout>
        )}
      </>
    );
  }
}

export default withRouter(App);
