import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import styles from "../../../Styles/inputField.module.css";
import UploadImage from "../../../Utils/UploadImage";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import Button from "../../../Components/Button";
import SubCategory from "../SubCategory";
import Loading from "../../../Components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { log } from "console";

// interface CreateOfferingProps {}

const CreateOffering = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [icon, setIcon] = useState({ obj: "", url: "" });
  const [hero, setHero] = useState({ obj: "", url: "" });
  const [testimonial, setTestimonial] = useState({ obj: "", url: "" });

  const [values, setValues] = useState({
    name: "",
    shortDescription: "",
    quote: "",
    paragraph: "",
    brand: "",
    city: "",
    country: "",
  });

  const [subCategory, setSubCategory]: any = useState([]);

  useEffect(() => {
    if (id) {
      getData();
      setLoading(true);
    }
  }, []);

  const handleImageUpdate = async () => {
    // we have three images icon, hero and testimonial
    let values = [];

    if (icon?.obj !== "") {
      // means we have to update icon
      let icon_name = getExtendedFileName(icon.obj);

      try {
        let val = await UploadImage({ file: icon.obj, name: icon_name });
        values.push(icon_name);
      } catch (e) {
        toast.error("Something went wrong while uploading icon!");
      }
    } else {
      values.push(icon.url);
    }

    if (hero?.obj !== "") {
      // means we have to update hero
      let hero_name = getExtendedFileName(hero.obj);

      try {
        let val = await UploadImage({ file: hero.obj, name: hero_name });
        values.push(hero_name);
      } catch (e) {
        toast.error("Something went wrong while uploading hero!");
      }
    } else {
      values.push(hero.url);
    }

    if (testimonial?.obj !== "") {
      // means we have to update testimonial
      let testimonial_name = getExtendedFileName(testimonial.obj);

      try {
        let val = await UploadImage({
          file: testimonial.obj,
          name: testimonial_name,
        });
        values.push(testimonial_name);
      } catch (e) {
        toast.error("Something went wrong while uploading testimonial!");
      }
    } else {
      values.push(testimonial.url);
    }

    return values;
  };

  const handleSubCategoryImages = async () => {
    let values = [];

    if (subCategory.length === 0) {
      return [];
    }

    for (let i = 0; i < subCategory.length; i++) {
      if (subCategory[i].subImage.obj !== "") {
        let img_name = getExtendedFileName(subCategory[i].subImage.obj);

        try {
          let val = await UploadImage({
            file: subCategory[i].subImage.obj,
            name: img_name,
          });
          values.push({ ...subCategory[i], subImage: img_name });
        } catch (e) {
          toast.error("Something went wrong while uploading icon!");
        }
      } else {
        values.push({
          ...subCategory[i],
          subImage:
            subCategory[i].subImage.url.split("/")[
              subCategory[i].subImage.url.split("/").length - 1
            ],
        });
      }
    }

    return values;
  };

  const getData = () => {
    callApi({
      url: "getSolutionById/" + id,
      method: "get",
      type: "solution",
    })
      .then((res) => {
        console.log("update solution api called", res);
        setValues({
          name: res.data.data.name,
          shortDescription: res.data.data.shortDescription,
          quote: res.data.data.quote,
          paragraph: res.data.data.testimonial.paragraph,
          brand: res.data.data.testimonial.brand,
          city: res.data.data.testimonial.city,
          country: res.data.data.testimonial.country,
          // priority: res.data.data.priority,
        });

        setIcon({
          url: `${
            process.env.REACT_APP_IMAGE_LINK + "/" + res.data.data.iconUrl
          }`,
          obj: "",
        });

        setHero({
          url: `${
            process.env.REACT_APP_IMAGE_LINK + "/" + res.data.data.heroMedia[0]
          }`,
          obj: "",
        });

        setTestimonial({
          url: `${
            process.env.REACT_APP_IMAGE_LINK +
            "/" +
            res.data.data.testimonial.image
          }`,
          obj: "",
        });

        setSubCategory(
          res.data.data?.subCategory.map((val: any) => {
            console.log("returning :::: ", {
              title: val.title,
              subImage: {
                obj: "",
                url: `${process.env.REACT_APP_IMAGE_LINK + "/" + val.subImage}`,
              },
              product: [...val.product],
            });

            return {
              title: val.title,
              subImage: {
                obj: "",
                url: `${process.env.REACT_APP_IMAGE_LINK + "/" + val.subImage}`,
              },
              product: [...val.product],
            };
          })
        );

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong while fetching data!");
        setLoading(false);
      });
  };

  const handleAdd = () => {
    setSubCategory((prev: any) => {
      prev.push({ title: "", subImage: { obj: "", url: "" }, product: [] });
      return [...prev];
    });
  };

  const updateName = (e: any, index: number) => {
    const name = e.target.name;

    if (name === "subImage") {
      setSubCategory((prev: any) => {
        prev[index].subImage = {
          obj: e.target.files[0],
          url: URL.createObjectURL(e.target.files[0]),
        };
        return [...prev];
      });
    } else {
      const value = e.target.value;
      setSubCategory((prev: any) => {
        prev[index][name] = value;
        return [...prev];
      });
    }
  };

  const handleRemove = (index: number) => {
    console.log(index);
    setSubCategory((prev: any) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const {
    name,

    shortDescription,

    quote,

    paragraph,
    brand,
    city,
    country,
  } = values;

  const onChange = (e: any) => {
    let name = e.target.name;

    if (name === "iconUrl" || name === "heroMedia" || name === "image") {
      if (name === "iconUrl") {
        setIcon({
          obj: e.target.files[0],
          url: URL.createObjectURL(e.target.files[0]),
        });
      } else if (name === "heroMedia") {
        setHero({
          obj: e.target.files[0],
          url: URL.createObjectURL(e.target.files[0]),
        });
      } else if (name === "image") {
        setTestimonial({
          obj: e.target.files[0],
          url: URL.createObjectURL(e.target.files[0]),
        });
      }
    } else {
      let value = e.target.value;
      setValues({ ...values, [name]: value });
    }
  };

  const validate = () => {
    if (!name) {
      toast.error("Title is required!");
      return false;
    }

    if (!shortDescription) {
      toast.error("Type is required!");
      return false;
    }

    if (!icon.url) {
      toast.error("Visibility is required!");
      return false;
    }

    if (!hero.url) {
      toast.error("Media is required!");
      return false;
    }

    return true;
  };

  const handleClick = async (e: any) => {
    e.preventDefault();
    if (e.target.name === "discard") {
      setValues({
        name: "",
        shortDescription: "",
        quote: "",
        paragraph: "",
        brand: "",
        city: "",
        country: "",
      });
      navigate("/offerings-solutions");
    } else if (e.target.name === "create") {
      if (id) {
        updateData();
      } else {
        setLoading(true);

        let [iconUrl, heroUrl, testimonialUrl] = await handleImageUpdate();

        let subCatValues = await handleSubCategoryImages();

        console.log("Sub Category valuessss :::::::: ", subCatValues);

        callApi({
          url: "addSolution",
          method: "post",
          type: "solution",
          data: {
            name: name,
            iconUrl: iconUrl,
            shortDescription: shortDescription,
            heroMedia: heroUrl,
            quote: quote,
            testimonial: {
              image: testimonialUrl,
              paragraph: paragraph,
              brand: brand,
              city: city,
              country: country,
            },
            subCategory: subCatValues,
          },
          header: true,
        })
          .then((_res: any) => {
            setValues({
              name: "",
              shortDescription: "",
              quote: "",
              paragraph: "",
              brand: "",
              city: "",
              country: "",
            });
            setLoading(false);
            toast.success("Successfully Created!");
            navigate("/offerings-solutions");
          })
          .catch((_e) => {
            toast.error("Something went wrong!");
            setLoading(false);
          });
      }
    }
  };

  const updateData = async () => {
    setLoading(true);

    let [iconUrl, heroUrl, testimonialUrl] = await handleImageUpdate();

    iconUrl =
      iconUrl.split("/").length > 1
        ? iconUrl.split("/")[iconUrl.split("/").length - 1]
        : iconUrl;

    heroUrl =
      heroUrl.split("/").length > 1
        ? heroUrl.split("/")[heroUrl.split("/").length - 1]
        : heroUrl;

    testimonialUrl =
      testimonialUrl.split("/").length > 1
        ? testimonialUrl.split("/")[testimonialUrl.split("/").length - 1]
        : testimonialUrl;

    console.log(
      "Values ssss ss ss ss s ss ::::: ",
      iconUrl,
      heroUrl,
      testimonialUrl
    );

    let subCatValues = await handleSubCategoryImages();

    callApi({
      url: `updateSolution/${id}`,
      method: "patch",
      type: "solution",
      data: {
        name: name,
        iconUrl: iconUrl,
        shortDescription: shortDescription,
        heroMedia: heroUrl,
        quote: quote,
        testimonial: {
          image: testimonialUrl,
          paragraph: paragraph,
          brand: brand,
          city: city,
          country: country,
        },
        subCategory: subCatValues,
      },
      header: true,
    })
      .then((res: any) => {
        setValues({
          name: "",
          shortDescription: "",
          quote: "",
          paragraph: "",
          brand: "",
          city: "",
          country: "",
        });
        setLoading(false);
        toast.success("Updated Successfully!");
        navigate("/offerings-solutions");
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        setLoading(false);
      });
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Content>
        <ContentHead
          title="Create Solution"
          showBtn1={true}
          btn1Name="create"
          btn1type="filled"
          btn1Text="Create"
          onClickHandler={handleClick}
          showBtn2={true}
          btn2Name="discard"
          btn2Text="Discard"
          btn2type="outlined"
        />
        <ContentBody>
          <div
            style={{
              display: "grid",
              rowGap: 12,
              gridAutoRows: "auto",
            }}
          >
            <InputField
              isLabel={false}
              value={name}
              lable={"Name"}
              type={"text"}
              isRequired={false}
              placeholder={"Name"}
              name={"name"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <div className={styles.field_contianer}>
              <label style={{ fontWeight: 500 }} htmlFor="">
                Icon
              </label>
              {icon.url === "" ? (
                <input
                  type="file"
                  name="iconUrl"
                  accept="image"
                  onChange={onChange}
                />
              ) : (
                <div
                  style={{
                    height: "250px",
                    aspectRatio: "1/1",
                    background: `url('${icon.url}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    borderRadius: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    color="white"
                    style={{
                      position: "absolute",
                      height: "20px",
                      aspectRatio: "1/1",
                      background: "grey",
                      borderRadius: "100%",
                      padding: "4px",
                      left: "calc(100% - 20px)",
                      top: "-10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIcon({ obj: "", url: "" })}
                  />
                </div>
              )}
            </div>
            <InputField
              isLabel={false}
              value={shortDescription}
              lable={"Short Description"}
              type={"text"}
              isRequired={false}
              placeholder={"Short Description"}
              name={"shortDescription"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <div className={styles.field_contianer}>
              <label style={{ fontWeight: 500 }} htmlFor="">
                Hero Media
              </label>
              {hero.url === "" ? (
                <input
                  type="file"
                  name="heroMedia"
                  accept="image"
                  onChange={onChange}
                />
              ) : (
                <div
                  style={{
                    height: "200px",
                    aspectRatio: "9/5",
                    background: `url('${hero.url}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    borderRadius: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    color="white"
                    style={{
                      position: "absolute",
                      height: "20px",
                      aspectRatio: "1/1",
                      background: "grey",
                      borderRadius: "100%",
                      padding: "4px",
                      left: "calc(100% - 20px)",
                      top: "-10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setHero({ obj: "", url: "" })}
                  />
                </div>
              )}
            </div>
            <InputField
              isLabel={false}
              value={quote}
              lable={"Quote"}
              type={"text"}
              isRequired={false}
              placeholder={"Quote"}
              name={"quote"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <div className={styles.field_contianer}>
              <label style={{ fontWeight: 500 }} htmlFor="">
                Testimonial Image
              </label>
              {testimonial.url === "" ? (
                <input
                  type="file"
                  name="image"
                  accept="image"
                  onChange={onChange}
                />
              ) : (
                <div
                  style={{
                    height: "200px",
                    aspectRatio: "9/5",
                    background: `url('${testimonial.url}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    borderRadius: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    color="white"
                    style={{
                      position: "absolute",
                      height: "20px",
                      aspectRatio: "1/1",
                      background: "grey",
                      borderRadius: "100%",
                      padding: "4px",
                      left: "calc(100% - 20px)",
                      top: "-10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setTestimonial({ obj: "", url: "" })}
                  />
                </div>
              )}
            </div>
            <InputField
              isLabel={false}
              value={paragraph}
              lable={"Testimonial Paragraph"}
              type={"text"}
              isRequired={false}
              placeholder={"Testimonial Paragraph"}
              name={"paragraph"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={brand}
              lable={"Testimonial Brand"}
              type={"text"}
              isRequired={false}
              placeholder={"Testimonial Brand"}
              name={"brand"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={city}
              lable={"Testimonial City"}
              type={"text"}
              isRequired={false}
              placeholder={"Testimonial City"}
              name={"city"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={country}
              lable={"Testimonial Country"}
              type={"text"}
              isRequired={false}
              placeholder={"Testimonial Country"}
              name={"country"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <label htmlFor="" style={{ fontWeight: 500 }}>
              Sub Category
            </label>
            <Button
              text={"Add More"}
              type={"outlined"}
              name={"add"}
              clickHandler={handleAdd}
              isIcon=""
              style={{
                padding: "4px 12px",
                marginBottom: 20,
              }}
            />
            <div
              style={{
                display: "grid",
                gridAutoFlow: "row",
                rowGap: 28,
              }}
            >
              {subCategory.map((item: any, index: number) => {
                return (
                  <SubCategory
                    key={index}
                    data={item}
                    index={index}
                    remove={() => handleRemove(index)}
                    updateName={(e: any) => updateName(e, index)}
                    updateTag={setSubCategory}
                    removeImage={(indx: any) => {
                      setSubCategory((prev: any) => {
                        prev[indx].subImage = { obj: "", url: "" };
                        return [...prev];
                      });
                    }}
                    removeTag={(indx: any, tags: any) => {
                      setSubCategory((prev: any) => {
                        prev[indx].product = [...tags];
                        return [...prev];
                      });
                    }}
                    addTag={(indx: any, tag: any) => {
                      setSubCategory((prev: any) => {
                        prev[indx].product = [...prev[index].product, tag];
                        return [...prev];
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default CreateOffering;
