import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import TextEditor from "../../../Components/TextEditor";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import UploadImage from "../../../Utils/UploadImage";
import styles from "../../../Styles/hero.module.css";
import Loading from "../../../Components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { isValidURL } from "../../../Utils/utils";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import ReactPlayer from 'react-player'

// interface CreateHeroProps {}

const CreateHero = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [imageChanged, setImageChanged] = useState(false);

  const [values, setValues] = useState({
    priority: "",
    mediaUrl: "",
    title: "",
    comment: "",
    onclickUrl: "",
  });

  const { priority, mediaUrl, title, comment, onclickUrl } = values;

  useEffect(() => {
    if (id) {
      setLoading(true);
      getData();
    }
  }, []);

  const getData = () => {
    callApi({
      url: `getHeroById/${id}`,
      method: "get",
      type: "hero",
    })
      .then((res) => {
        // console.log(res);
        setValues({
          priority: res.data.data.priority,
          mediaUrl: res.data.data.mediaUrl,
          title: res.data.data.title,
          comment: res.data.data.comment,
          onclickUrl: res.data.data.onclickUrl,
        });
        setImage(res.data.data.mediaUrl);

        setLoading(false);
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        setLoading(false);
      });
  };

  const onChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const onTitleChange = (value: any) => {
    console.log(value);
    setValues({ ...values, title: value });
  };
  const oncommentChange = (value: any) => {
    setValues({ ...values, comment: value });
  };

  const [image, setImage]: any = useState(null);

  const handleImage = (e: any) => {
    let value = e.target.files[0];
    setImage(value);
  };

  const validate = () => {
    if (image === null) {
      toast.error("Image is required!");
      return false;
    }
    if (!title) {
      toast.error("Title is required!");
      return false;
    }
    // if (!onclickUrl) {
    //   toast.error("OnClick Url is required!");
    //   return false;
    // }
    if (onclickUrl) {
      if (!isValidURL(onclickUrl)) {
        toast.error("Invalid Url!");
        return false;
      }
    }
    if (!priority) {
      toast.error("Priority is required!");
      return false;
    }

    return true;
  };

  const handleClick = (e: any) => {
    e.preventDefault();

    if (e.target.name === "discard") {
      setValues({
        priority: "",
        mediaUrl: "",
        title: "",
        comment: "",
        onclickUrl: "",
      });

      navigate("/");
    } else if (e.target.name === "create") {
      if (validate()) {
        if (id) {
          updateHandler();
        } else {
          setLoading(true);
          let name = getExtendedFileName(image);

          UploadImage({ file: image, name }).then((_res) => {
            callApi({
              url: "addHero",
              method: "post",
              type: "hero",
              data: { ...values, mediaUrl: name },
              header: true,
            })
              .then((res: any) => {
                setValues({
                  priority: "",
                  mediaUrl: "",
                  title: "",
                  comment: "",
                  onclickUrl: "",
                });
                setLoading(false);
                toast.success("Successfully Created!");
                navigate("/");
              })
              .catch((_e) => {
                toast.error("Something went wrong!");
                setLoading(false);
              });
          });
        }
      }
    }
  };

  const updateHandler = () => {
    setLoading(true);

    if (imageChanged) {
      // image need to upload image
      let name = getExtendedFileName(image);

      UploadImage({ file: image, name }).then((_res) => {
        callApi({
          url: `updateHero/${id}`,
          method: "patch",
          type: "hero",
          data: { ...values, mediaUrl: name },
          header: true,
        })
          .then((res: any) => {
            setValues({
              priority: "",
              mediaUrl: "",
              title: "",
              comment: "",
              onclickUrl: "",
            });
            setLoading(false);
            toast.success("Updated Successfully!");
            navigate("/");
          })
          .catch((_e) => {
            toast.error("Something went wrong!");
            setLoading(false);
          });
      });
    } else {
      // didn't need to upload image
      callApi({
        url: `updateHero/${id}`,
        method: "patch",
        type: "hero",
        data: { ...values, mediaUrl: image },
        header: true,
      })
        .then(() => {
          setLoading(false);
          navigate("/");
          toast.success("Updated successfully.");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
//   useEffect(() => {
//     console.log(values);
//   }, [values]);
// if(image)
//   console.log(image.name)

  return (
    <>
      <Loading isLoading={loading} />
      <Content>
        <ContentHead
          title="Create Hero"
          showBtn1={true}
          btn1Name="create"
          btn1type="filled"
          btn1Text="Save"
          onClickHandler={handleClick}
          showBtn2={true}
          btn2Name="discard"
          btn2Text="Discard"
          btn2type="outlined"
        />
        <ContentBody>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              rowGap: 12,
              columnGap: 12,
              gridAutoRows: "auto",
            }}
          >
            <div style={{ gridColumn: "1/3" }}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  marginBottom: "5px",
                }}
              >
                Image/Video
              </p>
              {image===null ? (
                <div className={styles.select_image}>
                  +
                  <input
                    type="file"
                    name="mediaUrl"
                    accept="image"
                    onChange={handleImage}
                  />
                </div>
              ) : ( image.name &&
                
              image?.name?.split(".")[1] == "webp" ||
              image?.name?.split(".")[1] == "jpeg" ||
              image?.name?.split(".")[1] == "jpg" ||
              image?.name?.split(".")[1] == "png") ? (
                <div
                  className={styles.show_image}
                  style={{
                    backgroundImage: id
                      ? imageChanged
                        ? `url('${URL.createObjectURL(image)}')`
                        : `url('${process.env.REACT_APP_IMAGE_LINK}/${image}')`
                      : `url('${URL.createObjectURL(image)}')`,
                    width: "500px",
                    aspectRatio: "16/9",
                    borderRadius: "10px",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "rgba(0,0,0,.05)",
                    position: "relative",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    color="white"
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      height: "20px",
                      width: "20px",
                      background: "rgba(0,0,0,1)",
                      borderRadius: "50%",
                      padding: "5px",
                      transform: "translate(40%,-40%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setImage(null);
                      setImageChanged(true);
                    }}
                  />
                </div>
              ) : (
                 <div style={{position:"relative",width:"500px",aspectRatio:"16/9"}} >
                 <ReactPlayer
                 url={`https://ah-admin-images.s3.ap-south-1.amazonaws.com/${image}`}
                 playing
                  muted
                  loop
                  controls={false}
                  width="100%"
                  height="100%"
                  // className={styles.show_image}
                 />
                 
                
                <FontAwesomeIcon
                    icon={faClose}
                    color="white"
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      height: "20px",
                      width: "20px",
                      background: "rgba(0,0,0,1)",
                      borderRadius: "50%",
                      padding: "5px",
                      transform: "translate(40%,-40%)",
                      cursor: "pointer",
                     
                    }}
                    onClick={() => {
                      setImage(null);
                      setImageChanged(true);
                    }}
                  />
                </div>

               
              )}
            </div>
            {/* <InputField
              isLabel={true}
              value={title}
              lable={"Title"}
              type={"text"}
              isRequired={false}
              placeholder={"Title"}
              name={"title"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            /> */}
            <div style={{ gridColumn: "1/3" }}>
              <label style={{ fontWeight: 500 }}>Title</label>
              <TextEditor content={title} handleChange={onTitleChange} />
            </div>
            {/* <InputField
              isLabel={true}
              value={comment}
              lable={"Comment"}
              type={"text"}
              isRequired={false}
              placeholder={"Comment"}
              name={"comment"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            /> */}
            <div style={{ gridColumn: "1/3" }}>
              <label style={{ fontWeight: 500 }}>Comment</label>
              <TextEditor content={comment} handleChange={oncommentChange} />
            </div>
            <InputField
              isLabel={true}
              value={onclickUrl}
              lable={"OnClick Url"}
              type={"text"}
              isRequired={false}
              placeholder={"OnClick Url"}
              name={"onclickUrl"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={priority}
              lable={"Priority"}
              type={"select"}
              isRequired={false}
              placeholder={"Priority"}
              name={"priority"}
              onChange={onChange}
              options={[
                { title: "1", value: "1" },
                { title: "2", value: "2" },
                { title: "3", value: "3" },
                { title: "4", value: "4" },
                { title: "5", value: "5" },
              ]}
              accessText={"title"}
              accessValue={"value"}
            />
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default CreateHero;
