import * as Pages from "./Pages";

import {
  faImage,
  faBlog,
  faBriefcase,
  faDna,
  faPeopleGroup,
  faUserTie,
  faHandshake,
  faNewspaper,
  faAddressBook,
  faDatabase,
  faLock,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

export const sidebar = [
  {
    path: "/",
    title: "Hero",
    icon: faImage,
    Element: Pages.Hero,
    view: true,
  },
  {
    path: "/content",
    title: "Content",
    icon: faBlog,
    Element: Pages.Content,
    view: true,
  },
  {
    path: "/career",
    title: "Career",
    icon: faBriefcase,
    Element: Pages.Career,
    view: true,
  },
  {
    path: "/offerings-solutions",
    title: "Offerings & Solutions",
    icon: faDna,
    Element: Pages.Offerings,
    view: true,
  },
  {
    path: "/teams",
    title: "Teams",
    icon: faPeopleGroup,
    Element: Pages.Teams,
    view: true,
  },
  {
    path: "/clients",
    title: "Clients",
    icon: faUserTie,
    Element: Pages.Clients,
    view: true,
  },
  {
    path: "/partner",
    title: "Partner",
    icon: faHandshake,
    Element: Pages.Partner,
    view: true,
  },
  // {
  //   path: "downloads",
  //   title: "Downloads",
  //   icon: faDownload,
  //   Element: Pages.Downloads,
  //   view: true,
  // },
  {
    path: "/aspirants",
    title: "Aspirants",
    icon: faNewspaper,
    Element: Pages.Aspirants,
    view: true,
  },
  {
    path: "/contacts",
    title: "Contacts",
    icon: faAddressBook,
    Element: Pages.Contacts,
    view: true,
  },
  {
    path: "/company-data",
    title: "Company Data",
    icon: faDatabase,
    Element: Pages.CompanyData,
    view: true,
  },
  {
    path: "admins",
    title: "Admin",
    icon: faLock,
    Element: Pages.Admins,
    view: true,
  },
  {
    path: "/admins/update_admin/:id",
    Element: Pages.UpdateAdmin,
    view: false,
  },
  {
    path: "/admins/create",
    Element: Pages.CreateAdmin,
    view: false,
  },
  {
    path: "/content/update_content/:id",
    Element: Pages.UpdateContent,
    view: false,
  },
  {
    path: "/content/create",
    Element: Pages.CreateContent,
    view: false,
  },
  {
    path: "/career/create",
    Element: Pages.CreateCareer,
    view: false,
  },
  {
    path: "/career/update_career/:id",
    Element: Pages.UpdateCareer,
    view: false,
  },
  {
    path: "/teams/create",
    Element: Pages.CreateTeam,
    view: false,
  },
  {
    path: "/teams/update_team/:id",
    Element: Pages.UpdateTeam,
    view: false,
  },
  {
    path: "/clients/create",
    Element: Pages.CreateClient,
    view: false,
  },
  {
    path: "/clients/update_client/:id",
    Element: Pages.UpdateClient,
    view: false,
  },
  {
    path: "/partner/create",
    Element: Pages.CreatePartner,
    view: false,
  },
  {
    path: "/partner/update_partner/:id",
    Element: Pages.UpdatePartner,
    view: false,
  },
  {
    path: "/contacts/create",
    Element: Pages.CreateContact,
    view: false,
  },
  {
    path: "/contacts/update_contact/:id",
    Element: Pages.UpdateContact,
    view: false,
  },
  {
    path: "/company-data/create",
    Element: Pages.CreateCompany,
    view: false,
  },
  {
    path: "/company-data/update_company-data/:id",
    Element: Pages.UpdateCompany,
    view: false,
  },
  {
    path: "/offerings-solutions/create",
    Element: Pages.CreateOffering,
    view: false,
  },
  {
    path: "/offerings-solutions/update_offerings-solution/:id",
    Element: Pages.UpdateOffering,
    view: false,
  },
  {
    path: "/admins/create",
    Element: Pages.CreateAdmin,
    view: false,
  },
  {
    path: "/hero/create",
    Element: Pages.CreateHero,
    view: false,
  },
  {
    path: "hero/update_hero/:id",
    Element: Pages.UpdateHero,
    view: false,
  },
];
