import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import Content from "../../Components/Content/Content";
import React, { useEffect, useState } from "react";
import callApi from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Row from "./Row";
import axios from "axios";
import Loading from "../../Components/Loading";
import DraggableTable from "../../Components/DraggableTable";
import withRouter from "../../Components/withRouter";

class Hero extends React.Component<any, any> {
  state = {
    hero: [],
    loading: true,
    index1: 0,
    index2: 0,
  };

  componentDidMount(): void {
    this.getData();
  }

  getData = () => {
    callApi({
      url: "getAllHero",
      method: "get",
      type: "hero",
    })
      .then((res) => {
        this.setState({
          hero: res.data.data.data
            .map((val: any) => ({ ...val, id: val._id }))
            .sort((a: any, b: any) => a.priority - b.priority),
          loading: false,
        });
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        this.setState({ loading: false });
      });
  };

  handleDelete = (val: any, id: any) => {
    // event.stopPropagation();
    toast.promise(
      new Promise((resolve, reject) => {
        callApi({
          url: `deleteHero/${id}`,
          method: "delete",
          type: "hero",
          header: true,
        })
          .then((res) => {
            resolve(true);
            this.getData();
            // refreshData();
          })
          .catch((_e) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Something went wrong!",
      }
    );
  };

  handleSwap = (id1: any, id2: any, priority1: any, priority2: any) => {
    let temp: any = [...this.state.hero];

    let index1 = temp.findIndex((val: any) => val.id === id1);

    let index2 = temp.findIndex((val: any) => val.id === id2);

    this.setState({ index1: index1, index2: index2 }, () => {
      if (priority1 > priority2) {
        temp[index1].priority = priority2;
        // means picked from bottom to the top position
        for (let i = index2; i < index1; i++) {
          temp[i].priority = temp[i].priority + 1;
        }
      }

      if (priority2 > priority1) {
        // means picked from the top to bottom position
        temp[index1].priority = priority2;

        for (let i = index1 + 1; i < index2 + 1; i++) {
          temp[i].priority = temp[i].priority - 1;
        }
      }

      this.setState(
        {
          hero: temp.sort((a: any, b: any) => a.priority - b.priority),
          loading: true,
        },
        () => {
          this.updateForSwap();
        }
      );
    });
  };

  updateForSwap = async () => {
    // console.log("in update for swap ", this.state.index1, this.state.index2);

    if (this.state.index1 > this.state.index2) {
      for (let i = this.state.index2; i < this.state.index1 + 1; i++) {
        let temp = await callApi({
          method: "patch",
          // @ts-ignore
          data: { priority: this.state.hero[i].priority },
          // @ts-ignore
          url: "updateHero/" + this.state.hero[i].id,
          type: "hero",
          header: true,
        });

        console.log("temp - ", i);
      }

      console.log("refetching*******");

      this.getData();
    }

    if (this.state.index2 > this.state.index1) {
      for (let i = this.state.index1; i < this.state.index2 + 1; i++) {
        let temp = await callApi({
          // @ts-ignore
          url: "updateHero/" + this.state.hero[i].id,
          // @ts-ignore
          data: { priority: this.state.hero[i].priority },
          method: "patch",
          type: "hero",
          header: true,
        });

        console.log("temp - ", i);
      }

      console.log("refetching*******");

      this.getData();
    }
  };

  render(): React.ReactNode {
    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Content>
          <ContentHead
            title="Hero"
            showBtn1={true}
            btn1Name="create"
            btn1type="filled"
            btn1Text="Add New"
            isIcon
            onClickHandler={() => {
              this.props.router.navigate("/hero/create");
            }}
          />
          <ContentBody>
            <DraggableTable
              head={["Title", "OnClickUrl", "Comment", ""]}
              body={this.state.hero}
              body_keys={[
                "title",
                "onclickUrl",
                "comment",
                // "clientCountry",
                "action",
                "htmlelem",
              ]}
              onSwap={this.handleSwap}
              onDelete={this.handleDelete}
              onEdit={(id: any) =>
                this.props.router.navigate("/hero/update_hero/" + id)
              }
            />
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(Hero);
