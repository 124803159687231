import { Component, ReactNode, useEffect, useState } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import callApi from "../../Apis/callApi";
import { formatDate } from "../../Utils/FormatDate";
import axios from "axios";
import Loading from "../../Components/Loading";

import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import Content from "../../Components/Content/Content";
import DraggableTable from "../../Components/DraggableTable";
import withRouter from "../../Components/withRouter";

const TeamsOld = () => {
  const [team, setTeam] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const changeDate = (dateString: any) => {
    const dateObject = new Date(dateString);

    // Get the date in the format 'YYYY-MM-DD'
    const formattedDate = dateObject.toISOString().substring(0, 10);
    return formattedDate;
  };

  const getData = () => {
    callApi({
      url: "getAllTeam",
      method: "get",
      type: "team",
    })
      .then((res) => {
        setTeam(
          res.data.data.team
            .map((val: any) => ({
              ...val,
              id: val._id,
              joiningDate: changeDate(val.joiningDate),
            }))
            .sort((a: any, b: any) => a.priority - b.priority)
        );
        setLoading(false);
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (val: any, id: any) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `https://www.ahinternational.online/v1/team/deleteTeam/${id}`,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_AHI")}`,
          },
        })
          .then((res) => {
            getData();
            resolve(true);
          })
          .catch((_e) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Something went wrong!",
      }
    );
  };

  const handleSwap = (id1: any, id2: any, priority1: any, priority2: any) => {
    let temp = [...team];

    let index1 = temp.findIndex((val: any) => val.id === id1);
    let index2 = temp.findIndex((val: any) => val.id === id2);

    // @ts-ignore
    temp[index1].priority = priority2;
    // @ts-ignore
    temp[index2].priority = priority1;

    temp.sort((a: any, b: any) => a.priority - b.priority);

    setTeam(temp);

    setLoading(true);

    axios
      .patch("https://www.ahinternational.online/v1/team/swap", {
        id1: id1,
        id2: id2,
        priority1: priority1,
        priority2: priority2,
      })
      .then((res) => {
        console.log(res);
        getData();
      })
      .catch((err) => console.log("error updating", err));
  };

  return (
    <>
      <Loading isLoading={loading} />

      <Content>
        <ContentHead
          title="Teams"
          showBtn1={true}
          btn1type="filled"
          btn1Text="Add Team"
          onClickHandler={() => navigate("/teams/create")}
        />
        <ContentBody>
          <>
            <DraggableTable
              head={["Name", "Department", "Position", "Joining Date", ""]}
              body={team}
              body_keys={[
                // "sno",
                "name",
                "department",
                "position",
                "joiningDate",
                "action",
              ]}
              onSwap={handleSwap}
              onDelete={handleDelete}
              onEdit={(id: any) => navigate("/teams/update_team/" + id)}
            />
          </>
        </ContentBody>
      </Content>
    </>
  );
};

class Teams extends Component<any, any> {
  state = {
    team: [],
    loading: true,
    index1: 0,
    index2: 0,
  };

  componentDidMount(): void {
    this.getData();
  }

  changeDate = (dateString: any) => {
    const dateObject = new Date(dateString);

    // Get the date in the format 'YYYY-MM-DD'
    const formattedDate = dateObject.toISOString().substring(0, 10);
    return formattedDate;
  };

  getData = () => {
    callApi({
      url: "getAllTeam",
      method: "get",
      type: "team",
    })
      .then((res) => {
        this.setState({
          team: res.data.data.team
            .map((val: any) => ({
              ...val,
              id: val._id,
              joiningDate: this.changeDate(val.joiningDate),
            }))
            .sort((a: any, b: any) => a.priority - b.priority),
          loading: false,
        });
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        this.setState({ loading: false });
      });
  };

  handleDelete = (val: any, id: any) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `https://www.ahinternational.online/v1/team/deleteTeam/${id}`,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_AHI")}`,
          },
        })
          .then((res) => {
            this.getData();
            resolve(true);
          })
          .catch((_e) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Something went wrong!",
      }
    );
  };

  handleSwap = (id1: any, id2: any, priority1: any, priority2: any) => {
    let temp: any = [...this.state.team];

    let index1 = temp.findIndex((val: any) => val.id === id1);

    let index2 = temp.findIndex((val: any) => val.id === id2);

    this.setState({ index1: index1, index2: index2 }, () => {
      if (priority1 > priority2) {
        temp[index1].priority = priority2;
        // means picked from bottom to the top position
        for (let i = index2; i < index1; i++) {
          temp[i].priority = temp[i].priority + 1;
        }
      }

      if (priority2 > priority1) {
        // means picked from the top to bottom position
        temp[index1].priority = priority2;

        for (let i = index1 + 1; i < index2 + 1; i++) {
          temp[i].priority = temp[i].priority - 1;
        }
      }

      this.setState(
        {
          team: temp.sort((a: any, b: any) => a.priority - b.priority),
          loading: true,
        },
        () => {
          this.updateForSwap();
        }
      );
    });
  };

  updateForSwap = async () => {
    // console.log("in update for swap ", this.state.index1, this.state.index2);

    if (this.state.index1 > this.state.index2) {
      for (let i = this.state.index2; i < this.state.index1 + 1; i++) {
        let temp = await callApi({
          method: "patch",
          // @ts-ignore
          data: { priority: this.state.team[i].priority },
          // @ts-ignore
          url: "updateTeam/" + this.state.team[i].id,
          type: "team",
          header: true,
        });

        console.log("temp - ", i);
      }

      console.log("refetching*******");

      this.getData();
    }

    if (this.state.index2 > this.state.index1) {
      for (let i = this.state.index1; i < this.state.index2 + 1; i++) {
        let temp = await callApi({
          // @ts-ignore
          url: "updateTeam/" + this.state.team[i].id,
          // @ts-ignore
          data: { priority: this.state.team[i].priority },
          method: "patch",
          type: "team",
          header: true,
        });

        console.log("temp - ", i);
      }

      console.log("refetching*******");

      this.getData();
    }
  };

  render(): ReactNode {
    return (
      <>
        <Loading isLoading={this.state.loading} />

        <Content>
          <ContentHead
            title="Teams"
            showBtn1={true}
            btn1type="filled"
            btn1Text="Add Team"
            onClickHandler={() => this.props.router.navigate("/teams/create")}
          />
          <ContentBody>
            <>
              <DraggableTable
                head={["Name", "Department", "Position", "Joining Date", ""]}
                body={this.state.team}
                body_keys={[
                  // "sno",
                  "name",
                  "department",
                  "position",
                  "joiningDate",
                  "action",
                ]}
                onSwap={this.handleSwap}
                onDelete={this.handleDelete}
                onEdit={(id: any) =>
                  this.props.router.navigate("/teams/update_team/" + id)
                }
              />
            </>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(Teams);
