import { useEffect, useState } from "react";
import InputField from "../../../Components/InputField";
import callApi from "../../../Apis/callApi";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../../Components/Content/Content";
import ContentHead from "../../../Components/Content/ContentHead";
import ContentBody from "../../../Components/Content/ContentBody";
import TextEditor from "../../../Components/TextEditor";
import Tags from "../../../Components/Tags";
import styles from "../../../Styles/inputField.module.css";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import UploadImage from "../../../Utils/UploadImage";
import Loading from "../../../Components/Loading";
import CreateContent from "../createContent/Index";

// interface UpdateContentProps {}

// const UpdateContent = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);

//   const [values, setValues] = useState({
//     title: "",
//     shortDescription: "",
//     type: "",
//     category: "",
//     tag: "",
//     mediaUrl: "",
//     attachmentUrl: "",
//     body: "",
//     visibility: "",
//   });

//   const [tags, setTags] = useState<any>([]);

//   useEffect(() => {
//     callApi({
//       url: `getContentById/${id}`,
//       method: "get",
//       type: "content",
//     })
//       .then((res: any) => {
//         setValues({
//           ...values,
//           title: res.data.data.title,
//           shortDescription: res.data.data.shortDescription,
//           type: res.data.data.type,
//           category: res.data.data.category,
//           mediaUrl: res.data.data.mediaUrl,
//           attachmentUrl: res.data.data.attachmentUrl,
//           visibility: res.data.data.visibility,
//           body: res.data.data.body,
//         });
//         setTags(res.data.data.tags);
//         setLoading(false);
//       })
//       .catch((e) => {
//         toast.error(e.response.data.message);
//         setLoading(false);
//       });
//   }, []);

//   const {
//     title,
//     shortDescription,
//     type,
//     category,
//     tag,
//     mediaUrl,
//     attachmentUrl,
//     body,
//     visibility,
//   } = values;

//   const onChange = (e: any) => {
//     const name = e.target.name;
//     const value =
//       name === "mediaUrl" || name === "attachmentUrl"
//         ? e.target.files[0]
//         : e.target.value;
//     setValues({ ...values, [name]: value });
//   };

//   const onBodyChange = (value: any) => {
//     setValues({ ...values, body: value });
//   };

//   const handleClick = (e: any) => {
//     e.preventDefault();
//     if (e.target.name === "discard") {
//       setValues({
//         title: "",
//         shortDescription: "",
//         type: "",
//         category: "",
//         tag: "",
//         mediaUrl: "",
//         attachmentUrl: "",
//         body: "",
//         visibility: "",
//       });
//       navigate("/content");
//     } else if (e.target.name === "update") {
//       setLoading(true);
//       UploadImage({
//         file: mediaUrl,
//         name: getExtendedFileName(mediaUrl),
//       })
//         .then(() => {
//           UploadImage({
//             file: attachmentUrl,
//             name: getExtendedFileName(attachmentUrl),
//           })
//             .then(() => {
//               callApi({
//                 url: `updateContent/${id}`,
//                 method: "patch",
//                 type: "content",
//                 data: {
//                   ...values,
//                   tags: tags,
//                   mediaUrl: getExtendedFileName(mediaUrl),
//                   attachmentUrl: getExtendedFileName(attachmentUrl),
//                   tag: undefined,
//                 },
//                 header: true,
//               })
//                 .then((res: any) => {
//                   setValues({
//                     title: "",
//                     shortDescription: "",
//                     type: "",
//                     category: "",
//                     tag: "",
//                     mediaUrl: "",
//                     attachmentUrl: "",
//                     body: "",
//                     visibility: "",
//                   });
//                   toast.success("Successfully Updated!");
//                   setLoading(false);
//                 })
//                 .catch((_e) => {
//                   toast.error("Something went wrong!");
//                   setLoading(false);
//                 });
//             })
//             .catch();
//         })
//         .catch();
//     }
//   };

//   return (
//     <>
//       <Loading isLoading={loading} />
//       <Content>
//         <ContentHead
//           title="Update Content"
//           showBtn1={true}
//           btn1Name="update"
//           btn1type="filled"
//           btn1Text="Update"
//           onClickHandler={handleClick}
//           showBtn2={true}
//           btn2Name="discard"
//           btn2Text="Discard"
//           btn2type="outlined"
//         />
//         <ContentBody>
//           <div
//             style={{
//               display: "grid",
//               rowGap: 12,
//               gridAutoRows: "auto",
//             }}
//           >
//             <InputField
//               isLabel={false}
//               value={title}
//               lable={"Title"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Title"}
//               name={"title"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={false}
//               value={shortDescription}
//               lable={"Short Description"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Short Description"}
//               name={"shortDescription"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <InputField
//               isLabel={true}
//               value={type}
//               lable={"Type"}
//               type={"select"}
//               options={[
//                 { title: "Blog", value: "blog" },
//                 { title: "Focus", value: "focus" },
//                 { title: "News", value: "news" },
//                 { title: "Press Release", value: "pressRelease" },
//                 { title: "Focus Projects", value: "focusProjects" },
//               ]}
//               isRequired={false}
//               placeholder={"Type"}
//               name={"type"}
//               onChange={onChange}
//               accessText="title"
//               accessValue={"value"}
//             />
//             <InputField
//               isLabel={false}
//               value={category}
//               lable={"Category"}
//               type={"text"}
//               isRequired={false}
//               placeholder={"Category"}
//               name={"category"}
//               onChange={onChange}
//               options={""}
//               accessText={""}
//               accessValue={""}
//             />
//             <div className={styles.field_contianer}>
//               <label htmlFor="">Tags</label>
//               <input
//                 type="text"
//                 value={tag}
//                 placeholder="Tags"
//                 onChange={onChange}
//                 name="tag"
//                 onKeyDown={(e: any) => {
//                   if (e.key === "Enter") {
//                     setTags((prev: any) => [...prev, tag]);
//                     setValues({ ...values, tag: "" });
//                   }
//                 }}
//               />
//               <Tags tags={tags} updateTag={setTags} />
//             </div>
//             <div className={styles.field_contianer}>
//               <label style={{ fontWeight: 500 }} htmlFor="">
//                 Media
//               </label>
//               <input
//                 type="file"
//                 name="mediaUrl"
//                 accept="image"
//                 onChange={onChange}
//               />
//             </div>
//             <div className={styles.field_contianer}>
//               <label style={{ fontWeight: 500 }} htmlFor="">
//                 Attachment
//               </label>
//               <input
//                 type="file"
//                 name="attachmentUrl"
//                 accept="image"
//                 onChange={onChange}
//               />
//             </div>
//             <InputField
//               isLabel={true}
//               value={visibility}
//               lable={"Visibility"}
//               type={"select"}
//               isRequired={false}
//               placeholder={"Visibility"}
//               name={"visibility"}
//               onChange={onChange}
//               options={[
//                 { title: "High", value: "high" },
//                 { title: "Medium", value: "medium" },
//                 { title: "Limited", value: "limited" },
//               ]}
//               accessText={"title"}
//               accessValue={"value"}
//             />
//             <TextEditor content={body} handleChange={onBodyChange} />
//           </div>
//         </ContentBody>
//       </Content>
//     </>
//   );
// };

const UpdateContent = () => {
  return <CreateContent />;
};

export default UpdateContent;
