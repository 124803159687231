interface TagsProps {
  tags: [];
  updateTag: any;
}

const Tags = ({ tags, updateTag }: TagsProps) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 12,
        }}
      >
        {tags?.map((tag: any, indx: number) => {
          return (
            <span
              title="Click to Remove"
              onClick={() => updateTag(indx)}
              style={{
                width: "fit-content",
                background: "rgb(164, 206, 237)",
                padding: "4px 8px 4px 8px",
                borderRadius: 8,
                cursor: "pointer",
              }}
              key={indx}
            >
              {tag}
            </span>
          );
        })}
      </div>
    </>
  );
};

export default Tags;
