import React, { createContext, useEffect, useState } from "react";
import { sidebar as sbar } from "../Utils/Data";
import { findIndex, set } from "lodash";
import axios from "axios";
import { Navigate } from "react-router-dom";

const SideBarContext = createContext({});

type props = {
  children: any;
};

const remove_company_access = (array: any) => {
  const bIndex = findIndex(array, (val: any) => {
    return val.path === "/company-data";
  });
  array.splice(bIndex, 1);

  const cIndex = findIndex(array, (val: any) => {
    return val.path === "/company-data/create";
  });
  array.splice(cIndex, 1);

  const uIndex = findIndex(array, (val: any) => {
    return val.path === "/company-data/update_company-data/:id";
  });
  array.splice(uIndex, 1);

  return array;
};

const remove_hero_access = (array: any) => {
  const hIndex = findIndex(array, (val: any) => {
    return val.path === "/";
  });
  array.splice(hIndex, 1);

  const cIndex = findIndex(array, (val: any) => {
    return val.path === "/hero/create";
  });
  array.splice(cIndex, 1);

  const uIndex = findIndex(array, (val: any) => {
    return val.path === "/hero/update_hero/:id";
  });
  array.splice(uIndex, 1);

  return array;
};

const remove_job_access = (array: any) => {
  const cIndex = findIndex(array, (val: any) => {
    return val.path === "/career";
  });
  array.splice(cIndex, 1);

  const xIndex = findIndex(array, (val: any) => {
    return val.path === "/career/create";
  });
  array.splice(xIndex, 1);

  const uIndex = findIndex(array, (val: any) => {
    return val.path === "/career/update_career/:id";
  });
  array.splice(uIndex, 1);

  const aIndex = findIndex(array, (val: any) => {
    return val.path === "/aspirants";
  });
  array.splice(aIndex, 1);

  return array;
};

const remove_solution_access = (array: any) => {
  const cIndex = findIndex(array, (val: any) => {
    return val.path === "/offerings-solutions";
  });
  array.splice(cIndex, 1);

  const xIndex = findIndex(array, (val: any) => {
    return val.path === "/offerings-solutions/create";
  });
  array.splice(xIndex, 1);

  const uIndex = findIndex(array, (val: any) => {
    return val.path === "/offerings-solutions/update_offerings-solution/:id";
  });
  array.splice(uIndex, 1);

  return array;
};

const remove_admin_access = (array: any) => {
  const cIndex = findIndex(array, (val: any) => {
    return val.path === "/admin";
  });
  array.splice(cIndex, 1);

  const aIndex = findIndex(array, (val: any) => {
    return val.path === "/admin/create";
  });
  array.splice(cIndex, 1);

  return array;
};

const remove_teams_access = (array: any) => {
  const cIndex = findIndex(array, (val: any) => {
    return val.path === "/teams";
  });
  array.splice(cIndex, 1);

  const aIndex = findIndex(array, (val: any) => {
    return val.path === "/teams/create";
  });
  array.splice(cIndex, 1);

  const uIndex = findIndex(array, (val: any) => {
    return val.path === "/teams/update_team/:id";
  });
  array.splice(uIndex, 1);

  return array;
};

const remove_client_access = (array: any) => {
  const cIndex = findIndex(array, (val: any) => {
    return val.path === "/clients";
  });
  array.splice(cIndex, 1);

  const aIndex = findIndex(array, (val: any) => {
    return val.path === "/clients/create";
  });
  array.splice(cIndex, 1);

  const uIndex = findIndex(array, (val: any) => {
    return val.path === "/client/update_client/:id";
  });
  array.splice(uIndex, 1);

  return array;
};

const remove_content_access = (array: any) => {
  const cIndex = findIndex(array, (val: any) => {
    return val.path === "/content";
  });
  array.splice(cIndex, 1);

  const aIndex = findIndex(array, (val: any) => {
    return val.path === "/content/create";
  });
  array.splice(cIndex, 1);

  const uIndex = findIndex(array, (val: any) => {
    return val.path === "/content/update_content/:id";
  });
  array.splice(uIndex, 1);

  return array;
};

const remove_partner_access = (array: any) => {
  const cIndex = findIndex(array, (val: any) => {
    return val.path === "/partner";
  });
  array.splice(cIndex, 1);

  const aIndex = findIndex(array, (val: any) => {
    return val.path === "/partner/create";
  });
  array.splice(cIndex, 1);

  const uIndex = findIndex(array, (val: any) => {
    return val.path === "/partner/update_partner/:id";
  });
  array.splice(uIndex, 1);

  return array;
};

const SideBarProvider = (props: props) => {
  const [sidebar, setSidebar]: any = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("running context.......");

    if (localStorage.getItem("token_AHI") && user === null) {
      setLoading(true);
      axios({
        method: "get",
        url: `https://www.ahinternational.online/v1/admin/getDataByToken/${localStorage.getItem(
          "token_AHI"
        )}`,
      })
        .then((res) => {
          console.log("setting response", res);
          setUser(res.data.data);
          value.updateSideBar(res.data.data.role);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, []);

  const value = {
    sidebar: sidebar,
    loading: loading,
    updateSideBar: (access: any) => {
      let temp: any = sbar;

      // if manager
      if (access === "manager") {
        temp = remove_company_access(temp);
        temp = remove_hero_access(temp);
        temp = remove_job_access(temp);
        temp = remove_solution_access(temp);
        temp = remove_admin_access(temp);
        temp = remove_teams_access(temp);
      }

      // if hr
      // remove: Client, Company, Compliance, Content, Download, Hero, Partner, Solution, SuperAdmin
      if (access === "hr") {
        temp = remove_company_access(temp);
        temp = remove_hero_access(temp);
        temp = remove_solution_access(temp);
        temp = remove_admin_access(temp);
        temp = remove_client_access(temp);
        temp = remove_content_access(temp);
        temp = remove_partner_access(temp);
      }

      // if cm
      // remove:
      if (access === "cm") {
        let arr = [];

        const cIndex = findIndex(temp, (val: any) => {
          return val.path === "/contacts";
        });

        const ccIndex = findIndex(temp, (val: any) => {
          return val.path === "/contacts/create";
        });

        const cuIndex = findIndex(temp, (val: any) => {
          return val.path === "/contacts/update_contact/:id";
        });

        arr.push(...temp.splice(cIndex, 1));
        arr.push(...temp.splice(ccIndex, 1));
        arr.push(...temp.splice(cuIndex, 1));

        temp = arr;
      }

      //   if (access === "user") {
      //     temp = [];
      //   }

      let root = {
        index: true,
        Element: <Navigate to={temp[0].path} replace={true} />,
        view: false,
      };

      temp.unshift(root);

      setSidebar([...temp]);
    },
    updateUser: (val: any) => {
      setUser(val);
    },
  };

  return (
    <SideBarContext.Provider value={value}>
      {props.children}
    </SideBarContext.Provider>
  );
};

export { SideBarProvider, SideBarContext };

// Admin - access
// Client, Compnany, Compliance, Contact, Content, Download, Hero, Job, Partner, Solution, SuperAdmin, Team

// manager - access
// Client, Contact, Content, Download, Partner

// hr
// Contact, Job, Team
// remove: Client, Company, Compliance, Content, Download, Hero, Partner, Solution, SuperAdmin

// cm
// Contact
