import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import styles from "../../../Styles/inputField.module.css";
import UploadImage from "../../../Utils/UploadImage";
import CreateHero from "../createHero/Index";

const UpdateHero = () => {
  return <CreateHero />;
};

export default UpdateHero;
