import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import styles from "../../../Styles/inputField.module.css";
import styless from "../../../Styles/hero.module.css";
import UploadImage from "../../../Utils/UploadImage";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import Loading from "../../../Components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

// interface CreateTeamProps {}

const CreateTeam = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [imageChanged, setImageChanged] = useState(false);

  const [values, setValues] = useState({
    employeeId: "",
    name: "",
    department: "",
    position: "",
    joiningDate: "",
    // image: "",
    location: "",
    linkedin: "",
    bio: "",
  });

  const {
    employeeId,
    name,
    department,
    position,
    joiningDate,
    // image,
    location,
    linkedin,
    bio,
  } = values;

  useEffect(() => {
    if (id) {
      setLoading(true);
      getData();
    }
  }, []);

  const getData = () => {
    callApi({
      url: "getTeamById/" + id,
      method: "get",
      type: "team",
    })
      .then((res) => {
        // console.log("update client api called", res);

        setValues({
          employeeId: res.data.data.employeeId,
          name: res.data.data.name,
          department: res.data.data.department,
          position: res.data.data.position,
          joiningDate: res.data.data.joiningDate,
          // image: res.data.data.image,
          location: res.data.data.location,
          linkedin: res.data.data.linkedin,
          bio: res.data.data.bio,
        });
        setImage2(res.data.data.image);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong while fetching data!");
        setLoading(false);
      });
  };

  const onChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const [image2, setImage2]: any = useState(null);

  const handleImage = (e: any) => {
    let value = e.target.files[0];
    setImage2(value);
  };

  const validate = () => {
    if (image2 === null) {
      toast.error("Image is required!");
      return false;
    }
    if (!name) {
      toast.error("Name is required!");
      return false;
    }

    if (!employeeId) {
      toast.error("EmployeeId is required!");
      return false;
    }

    return true;
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    if (e.target.name === "discard") {
      setValues({
        employeeId: "",
        name: "",
        department: "",
        position: "",
        joiningDate: "",
        // image: "",
        location: "",
        linkedin: "",
        bio: "",
      });
      navigate("/teams");
    } else if (e.target.name === "create") {
      if (validate()) {
        if (id) {
          updateHandler();
        } else {
          setLoading(true);
          console.log(image2);
          let name = getExtendedFileName(image2);

          console.log(name);
          UploadImage({ file: image2, name }).then((_res) => {
            callApi({
              url: "addTeam",
              method: "post",
              type: "team",
              data: { ...values, image: name },
              header: true,
            })
              .then((res: any) => {
                setValues({
                  employeeId: "",
                  name: "",
                  department: "",
                  position: "",
                  joiningDate: "",
                  // image: "",
                  location: "",
                  linkedin: "",
                  bio: "",
                });
                setLoading(false);
                toast.success("Successfully Created!");
                // navigate("/");
              })
              .catch((_e) => {
                toast.error("Something went wrong!");
                setLoading(false);
              });
          });
        }
      }
    }
  };

  const updateHandler = () => {
    setLoading(true);

    if (imageChanged) {
      // image need to upload image
      let name = getExtendedFileName(image2);

      UploadImage({ file: image2, name }).then((_res) => {
        callApi({
          url: `updateTeam/${id}`,
          method: "patch",
          type: "team",
          data: { ...values, image: name },
          header: true,
        })
          .then((res: any) => {
            setValues({
              employeeId: "",
              name: "",
              department: "",
              position: "",
              joiningDate: "",
              // image: "",
              location: "",
              linkedin: "",
              bio: "",
            });
            setLoading(false);
            toast.success("Updated Successfully!");
            navigate("/teams");
          })
          .catch((_e) => {
            toast.error("Something went wrong!");
            setLoading(false);
          });
      });
    } else {
      // didn't need to upload image
      callApi({
        url: `updateTeam/${id}`,
        method: "patch",
        type: "team",
        data: { ...values, image: image2 },
        header: true,
      })
        .then(() => {
          setLoading(false);
          navigate("/teams");
          toast.success("Updated successfully.");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Content>
        <ContentHead
          title="Create Team"
          showBtn1={true}
          btn1Name="create"
          btn1type="filled"
          btn1Text="Create"
          onClickHandler={handleClick}
          showBtn2={true}
          btn2Name="discard"
          btn2Text="Discard"
          btn2type="outlined"
        />
        <ContentBody>
          <div
            style={{
              display: "grid",
              rowGap: 12,
              gridAutoRows: "auto",
            }}
          >
            <InputField
              isLabel={false}
              value={employeeId}
              lable={"Employee Id"}
              type={"text"}
              isRequired={false}
              placeholder={"Employee Id"}
              name={"employeeId"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={name}
              lable={"Name"}
              type={"text"}
              isRequired={false}
              placeholder={"Name"}
              name={"name"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={department}
              lable={"Department"}
              type={"text"}
              isRequired={false}
              placeholder={"Department"}
              name={"department"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={position}
              lable={"Position"}
              type={"text"}
              isRequired={false}
              placeholder={"Position"}
              name={"position"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={joiningDate}
              lable={"Joining Date"}
              type={"date"}
              isRequired={false}
              placeholder={"Joining Date"}
              name={"joiningDate"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <div
              // style={{ gridColumn: "1/3" }}
              className={styles.field_contianer}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  marginBottom: "5px",
                }}
              >
                Image
              </p>
              {image2 === null ? (
                // <div className={styless.select_image}>
                // +
                <input
                  type="file"
                  name="image"
                  accept="image"
                  onChange={handleImage}
                />
              ) : (
                // </div>
                <div
                  className={styles.show_image}
                  style={{
                    backgroundImage: id
                      ? imageChanged
                        ? `url('${
                            typeof image2 === "object"
                              ? URL.createObjectURL(image2)
                              : ""
                          }')`
                        : `url('${process.env.REACT_APP_IMAGE_LINK}/${image2}')`
                      : `url('${
                          typeof image2 === "object"
                            ? URL.createObjectURL(image2)
                            : ""
                        }')`,
                    width: "500px",
                    aspectRatio: "16/9",
                    borderRadius: "10px",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "rgba(0,0,0,.05)",
                    position: "relative",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    color="white"
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      height: "20px",
                      width: "20px",
                      background: "rgba(0,0,0,1)",
                      borderRadius: "50%",
                      padding: "5px",
                      transform: "translate(40%,-40%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setImage2(null);
                      setImageChanged(true);
                    }}
                  />
                </div>
              )}
            </div>
            <InputField
              isLabel={false}
              value={location}
              lable={"Location"}
              type={"text"}
              isRequired={false}
              placeholder={"Location"}
              name={"location"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={linkedin}
              lable={"Linkedin"}
              type={"text"}
              isRequired={false}
              placeholder={"Linkedin"}
              name={"linkedin"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={bio}
              lable={"Bio"}
              type={"text"}
              isRequired={false}
              placeholder={"Bio"}
              name={"bio"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default CreateTeam;
