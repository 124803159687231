import WithTableView from "../../GenericPages/WithTableView";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import callApi from "../../Apis/callApi";
import axios from "axios";
import Loading from "../../Components/Loading";

const Aspirants = () => {
  const [aspirant, setAspirant] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    callApi({
      url: "getAppliedJobs",
      method: "get",
      type: "career",
    })
      .then((res: any) => {
        setAspirant(res.data.applications);
        setLoading(false);
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (val: any, id: any) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: "https://www.ahinternational.online/v1/career/applyJob"+id,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_AHI")}`,
          },
        })
          .then((res) => {
            getData();
            resolve(true);
          })
          .catch((_e) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Something went wrong!",
      }
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <WithTableView
        heading="Aspirants"
        head={[
          { title: "S.No." },
          { title: "First Name" },
          { title: "Last Name" },
          { title: "Email" },
          { title: "Mobile Number" },
          { title: "Qualification" },
          { title: "" },
        ]}
        body={aspirant.map((el: any) => {
          return {
            id: el._id,
            firstName: el.firstName,
            lastName: el.lastName,
            email: el.email,
            mobileNumber: el.mobileNumber,
            qualification: el.qualification,
          };
        })}
        body_keys={[
          "firstName",
          "lastName",
          "email",
          "mobileNumber",
          "qualification",
          "action:delete",
        ]}
        show_btn_1={false}
        onActionClick={handleDelete}
      />
    </>
  );
};

export default Aspirants;
