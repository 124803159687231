import { Component, ReactNode, useEffect, useState } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import callApi from "../../Apis/callApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { get } from "lodash";
import Loading from "../../Components/Loading";

import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import Content from "../../Components/Content/Content";
import DraggableTable from "../../Components/DraggableTable";
import withRouter from "../../Components/withRouter";

class Clients extends Component<any, any> {
  state = {
    client: [],
    loading: true,
    index1: 0,
    index2: 0,
  };

  componentDidMount(): void {
    this.getData();
  }

  getData = () => {
    callApi({
      url: "getAllClient",
      method: "get",
      type: "client",
    })
      .then((res) => {
        this.setState({
          client: res.data.data.client
            .map((val: any) => ({ ...val, id: val._id }))
            .sort((a: any, b: any) => a.priority - b.priority),
          loading: false,
        });
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        this.setState({ loading: false });
      });
  };

  handleDelete = (val: any, id: any) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `https://www.ahinternational.online/v1/client/deleteClient/${id}`,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_AHI")}`,
          },
        })
          .then((res) => {
            this.getData();
            resolve(true);
          })
          .catch((_e) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Something went wrong!",
      }
    );
  };

  handleSwap = (id1: any, id2: any, priority1: any, priority2: any) => {
    let temp: any = [...this.state.client];

    let index1 = temp.findIndex((val: any) => val.id === id1);

    let index2 = temp.findIndex((val: any) => val.id === id2);

    this.setState({ index1: index1, index2: index2 }, () => {
      if (priority1 > priority2) {
        temp[index1].priority = priority2;
        // means picked from bottom to the top position
        for (let i = index2; i < index1; i++) {
          temp[i].priority = temp[i].priority + 1;
        }
      }

      if (priority2 > priority1) {
        // means picked from the top to bottom position
        temp[index1].priority = priority2;

        for (let i = index1 + 1; i < index2 + 1; i++) {
          temp[i].priority = temp[i].priority - 1;
        }
      }

      this.setState(
        {
          client: temp.sort((a: any, b: any) => a.priority - b.priority),
          loading: true,
        },
        () => {
          this.updateForSwap();
        }
      );
    });
  };

  updateForSwap = async () => {
    // console.log("in update for swap ", this.state.index1, this.state.index2);

    if (this.state.index1 > this.state.index2) {
      for (let i = this.state.index2; i < this.state.index1 + 1; i++) {
        let temp = await callApi({
          method: "patch",
          // @ts-ignore
          data: { priority: this.state.client[i].priority },
          // @ts-ignore
          url: "updateClient/" + this.state.client[i].id,
          type: "client",
          header: true,
        });

        console.log("temp - ", i);
      }

      console.log("refetching*******");

      this.getData();
    }

    if (this.state.index2 > this.state.index1) {
      for (let i = this.state.index1; i < this.state.index2 + 1; i++) {
        let temp = await callApi({
          // @ts-ignore
          url: "updateClient/" + this.state.client[i].id,
          // @ts-ignore
          data: { priority: this.state.client[i].priority },
          method: "patch",
          type: "client",
          header: true,
        });

        console.log("temp - ", i);
      }

      console.log("refetching*******");

      this.getData();
    }
  };

  render(): ReactNode {
    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Content>
          <ContentHead
            title="Clients"
            showBtn1={true}
            btn1type="filled"
            btn1Text="Add Client"
            onClickHandler={() => this.props.router.navigate("/clients/create")}
          />
          <ContentBody>
            <>
              <DraggableTable
                head={["Name", "Brand", "City", "Country", ""]}
                body={this.state.client}
                body_keys={[
                  "clientName",
                  "clientBrand",
                  "clientCity",
                  "clientCountry",
                  "action",
                ]}
                onSwap={this.handleSwap}
                onDelete={this.handleDelete}
                onEdit={(id: any) =>
                  this.props.router.navigate("/clients/update_client/" + id)
                }
              />
            </>
          </ContentBody>
        </Content>
      </>
    );
  }
}
export default withRouter(Clients);
