// import { useState, useEffect } from "react";
// import toast from "react-hot-toast";
// import { useNavigate, useParams } from "react-router-dom";
// import callApi from "../../../Apis/callApi";
// import ContentBody from "../../../Components/Content/ContentBody";
// import ContentHead from "../../../Components/Content/ContentHead";
// import InputField from "../../../Components/InputField";
// import Content from "../../../Components/Content/Content";
// import Button from "../../../Components/Button";
// import SubCategory from "../SubCategory";
// import styles from "../../../Styles/inputField.module.css";
// import Loading from "../../../Components/Loading";
import CreateOffering from "../createOffering/Index";

// interface UpdateOfferingProps {}

const UpdateOffering = () => {
  // const navigate = useNavigate();
  // const { id } = useParams();
  // const [loading, setLoading] = useState(false);

  // const [values, setValues] = useState({
  //   name: "",
  //   iconUrl: "",
  //   shortDescription: "",
  //   heroMedia: "",
  //   quote: "",
  //   image: "",
  //   paragraph: "",
  //   brand: "",
  //   city: "",
  //   country: "",
  // });

  // const [subCategory, setSubCategory] = useState([
  //   {
  //     title: "",
  //     subImage: "",
  //     product: [],
  //   },
  // ]);

  // useEffect(() => {
  //   if (id) {
  //     getData();
  //   }
  // }, []);

  // const getData = () => {
  //   callApi({
  //     url: "getSolutionById/" + id,
  //     method: "get",
  //     type: "solution",
  //   })
  //     .then((res) => {
  //       console.log("update solution api called", res);
  //       setValues({
  //         name: res.data.data.name,
  //         iconUrl: "",
  //         shortDescription: res.data.data.shortDescription,
  //         heroMedia: "",
  //         quote: res.data.data.quote,
  //         image: "",
  //         paragraph: res.data.data.quote.testimonial.paragraph,
  //         brand: res.data.data.quote.testimonial.brand,
  //         city: res.data.data.quote.testimonial.city,
  //         country: res.data.data.quote.testimonial.country,
  //         // priority: res.data.data.priority,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Something went wrong while fetching data!");
  //     });
  // };

  // const handleAdd = () => {
  //   setSubCategory((prev: any) => {
  //     prev.push({ title: "", subImage: "", product: [] });
  //     return [...prev];
  //   });
  // };

  // const updateName = (e: any, index: number) => {
  //   const name = e.target.name;
  //   const value = name === "subImage" ? e.target.files[0] : e.target.value;
  //   setSubCategory((prev: any) => {
  //     prev[index][name] = value;
  //     return [...prev];
  //   });
  // };

  // const handleRemove = (index: number) => {
  //   setSubCategory((prev: any) => {
  //     prev.splice(index, 1);
  //     return [...prev];
  //   });
  // };

  // const {
  //   name,
  //   iconUrl,
  //   shortDescription,
  //   heroMedia,
  //   quote,
  //   image,
  //   paragraph,
  //   brand,
  //   city,
  //   country,
  // } = values;

  // const onChange = (e: any) => {
  //   let name = e.target.name;
  //   let value =
  //     name === "iconUrl" || name === "heroMedia" || name === "image"
  //       ? e.target.files[0]
  //       : e.target.value;
  //   setValues({ ...values, [name]: value });
  // };

  // const handleClick = (e: any) => {
  //   e.preventDefault();
  //   if (e.target.name === "discard") {
  //     setValues({
  //       name: "",
  //       iconUrl: "",
  //       shortDescription: "",
  //       heroMedia: "",
  //       quote: "",
  //       image: "",
  //       paragraph: "",
  //       brand: "",
  //       city: "",
  //       country: "",
  //     });
  //     navigate("/offerings-solutions");
  //   } else if (e.target.name === "update") {
  //     callApi({
  //       url: `updateSolution/${id}`,
  //       method: "patch",
  //       type: "solution",
  //       data: {
  //         name: name,
  //         iconUrl: iconUrl,
  //         shortDescription: shortDescription,
  //         heroMedia: heroMedia,
  //         quote: quote,
  //         testimonial: {
  //           image: image,
  //           paragraph: paragraph,
  //           brand: brand,
  //           city: city,
  //           country: country,
  //         },
  //         subCategory: subCategory,
  //       },
  //       header: true,
  //     })
  //       .then((_res: any) => {
  //         setValues({
  //           name: "",
  //           iconUrl: "",
  //           shortDescription: "",
  //           heroMedia: "",
  //           quote: "",
  //           image: "",
  //           paragraph: "",
  //           brand: "",
  //           city: "",
  //           country: "",
  //         });
  //         toast.success("Successfully Updated!");
  //       })
  //       .catch((_e) => {
  //         toast.error("Something went wrong!");
  //       });
  //   }
  // };

  // return (
  //   <>
  //     <Loading isLoading={loading} />
  //     <Content>
  //       <ContentHead
  //         title="Update Solution"
  //         showBtn1={true}
  //         btn1Name="update"
  //         btn1type="filled"
  //         btn1Text="Update"
  //         onClickHandler={handleClick}
  //         showBtn2={true}
  //         btn2Name="discard"
  //         btn2Text="Discard"
  //         btn2type="outlined"
  //       />
  //       <ContentBody>
  //         <div
  //           style={{
  //             display: "grid",
  //             rowGap: 12,
  //             gridAutoRows: "auto",
  //           }}
  //         >
  //           <InputField
  //             isLabel={false}
  //             value={name}
  //             lable={"Name"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Name"}
  //             name={"name"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <div className={styles.field_contianer}>
  //             <label style={{ fontWeight: 500 }} htmlFor="">
  //               Icon
  //             </label>
  //             <input
  //               type="file"
  //               name="iconUrl"
  //               accept="image"
  //               onChange={onChange}
  //             />
  //           </div>
  //           <InputField
  //             isLabel={false}
  //             value={shortDescription}
  //             lable={"Short Description"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Short Description"}
  //             name={"shortDescription"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <div className={styles.field_contianer}>
  //             <label style={{ fontWeight: 500 }} htmlFor="">
  //               Hero Media
  //             </label>
  //             <input
  //               type="file"
  //               name="heroMedia"
  //               accept="image"
  //               onChange={onChange}
  //             />
  //           </div>
  //           <InputField
  //             isLabel={false}
  //             value={quote}
  //             lable={"Quote"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Quote"}
  //             name={"quote"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <div className={styles.field_contianer}>
  //             <label style={{ fontWeight: 500 }} htmlFor="">
  //               Testimonial Image
  //             </label>
  //             <input
  //               type="file"
  //               name="image"
  //               accept="image"
  //               onChange={onChange}
  //             />
  //           </div>
  //           <InputField
  //             isLabel={false}
  //             value={paragraph}
  //             lable={"Testimonial Paragraph"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Testimonial Paragraph"}
  //             name={"paragraph"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={brand}
  //             lable={"Testimonial Brand"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Testimonial Brand"}
  //             name={"brand"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={city}
  //             lable={"Testimonial City"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Testimonial City"}
  //             name={"city"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={country}
  //             lable={"Testimonial Country"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Testimonial Country"}
  //             name={"country"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <label htmlFor="" style={{ fontWeight: 500 }}>
  //             Sub Category
  //           </label>
  //           <Button
  //             text={"Add More"}
  //             type={"outlined"}
  //             name={"add"}
  //             clickHandler={handleAdd}
  //             isIcon=""
  //             style={{
  //               padding: "4px 12px",
  //               marginBottom: 20,
  //             }}
  //           />
  //           <div
  //             style={{
  //               display: "grid",
  //               gridAutoFlow: "row",
  //               rowGap: 28,
  //             }}
  //           >
  //             {subCategory.map((item: any, index: number) => {
  //               return (
  //                 <SubCategory
  //                   data={item}
  //                   index={index}
  //                   remove={handleRemove}
  //                   updateName={(e: any) => updateName(e, index)}
  //                   updateTag={setSubCategory}
  //                 />
  //               );
  //             })}
  //           </div>
  //         </div>
  //       </ContentBody>
  //     </Content>
  //   </>
  // );

  return <CreateOffering/>
};

export default UpdateOffering;
