import { useEffect, useState } from "react";
import InputField from "../../../Components/InputField";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import TextEditor from "../../../Components/TextEditor";
import Content from "../../../Components/Content/Content";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import { toast } from "react-hot-toast";
import styles from "../../../Styles/inputField.module.css";
import styless from "../../../Styles/content.module.css";
import Tags from "../../../Components/Tags";
import UploadImage from "../../../Utils/UploadImage";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import Loading from "../../../Components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

// interface CreateContentProps {}

const CreateContent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [imageChanged, setImageChanged] = useState(false);
  const [attachmentChanged, setAttachmentChanged] = useState(false);

  const [values, setValues] = useState({
    title: "",
    shortDescription: "",
    type: "",
    category: "",
    tag: "",
    mediaUrl: null,
    attachmentUrl: null,
    body: "",
    visibility: "",
  });

  const {
    title,
    shortDescription,
    type,
    category,
    tag,
    mediaUrl,
    attachmentUrl,
    body,
    visibility,
  } = values;

  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    if (id) {
      getData();
      setLoading(true);
    }
  }, []);

  const getData = () => {
    callApi({
      url: `getContentById/${id}`,
      method: "get",
      type: "content",
    })
      .then((res: any) => {
        const data = {
          tag: "",
          title: res.data.data.title,
          shortDescription: res.data.data.shortDescription,
          type: res.data.data.type,
          category: res.data.data.category,
          mediaUrl: res.data.data.mediaUrl,
          attachmentUrl: res.data.data.attachmentUrl,
          visibility: res.data.data.visibility,
          body: res.data.data.body,
        };

        setValues(data);
        setTags(res.data.data.tags);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {}, [values]);

  const onChange = (e: any) => {
    const name = e.target.name;
    const value =
      name === "mediaUrl" || name === "attachmentUrl"
        ? e.target.files[0]
        : e.target.value;
    setValues({ ...values, [name]: value });

    if (name === "attachmentUrl") {
      setAttachmentChanged(true);
    }
  };

  const onBodyChange = (value: any) => {
    setValues((prev) => ({ ...prev, body: value }));
  };

  const validate = () => {
    if (!title) {
      toast.error("Title is required!");
      return false;
    }

    if (!type) {
      toast.error("Type is required!");
      return false;
    }

    if (!visibility) {
      toast.error("Visibility is required!");
      return false;
    }

    if (!mediaUrl) {
      toast.error("Media is required!");
      return false;
    }

    // if (!attachmentUrl) {
    //   toast.error("Attachment is required!");
    //   return false;
    // }

    return true;
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    if (e.target.name === "discard") {
      setValues({
        title: "",
        shortDescription: "",
        type: "",
        category: "",
        tag: "",
        mediaUrl: null,
        attachmentUrl: null,
        body: "",
        visibility: "",
      });
      navigate("/content");
    } else if (e.target.name === "create") {
      if (id) {
        updateData();
      } else {
        if (validate()) {
          setLoading(true);

          if (attachmentUrl) {
            let name = getExtendedFileName(mediaUrl);
            let filename = getExtendedFileName(attachmentUrl);

            UploadImage({
              file: mediaUrl,
              name: name,
            })
              .then(() => {
                UploadImage({
                  file: attachmentUrl,
                  name: filename,
                })
                  .then(() => {
                    callApi({
                      url: "addContent",
                      method: "post",
                      type: "content",
                      data: {
                        ...values,
                        tags: tags,
                        mediaUrl: name,
                        attachmentUrl: filename,
                        tag: undefined,
                      },
                      header: true,
                    })
                      .then((res: any) => {
                        setValues({
                          title: "",
                          shortDescription: "",
                          type: "",
                          category: "",
                          tag: "",
                          mediaUrl: null,
                          attachmentUrl: null,
                          body: "",
                          visibility: "",
                        });
                        setLoading(false);
                        if (res.status === 201) {
                          toast.success("Successfully Created!");
                          navigate("/content");
                        }
                      })
                      .catch((_e) => {
                        toast.error("Something went wrong!");
                        setLoading(false);
                      });
                  })
                  .catch();
              })
              .catch();
          } else {
            let name = getExtendedFileName(mediaUrl);

            UploadImage({
              file: mediaUrl,
              name: name,
            })
              .then(() => {
                callApi({
                  url: "addContent",
                  method: "post",
                  type: "content",
                  data: {
                    ...values,
                    tags: tags,
                    mediaUrl: name,
                    // attachmentUrl: "",
                    tag: undefined,
                  },
                  header: true,
                })
                  .then((res: any) => {
                    setValues({
                      title: "",
                      shortDescription: "",
                      type: "",
                      category: "",
                      tag: "",
                      mediaUrl: null,
                      attachmentUrl: null,
                      body: "",
                      visibility: "",
                    });
                    setLoading(false);
                    if (res.status === 201) {
                      toast.success("Successfully Created!");
                      navigate("/content");
                    }
                  })
                  .catch((_e) => {
                    toast.error("Something went wrong!");
                    setLoading(false);
                  });
              })
              .catch(() => {
                toast.error("Error while uploading image.");
                setLoading(false);
              });
          }
        }
      }
    }
  };

  const updateData = () => {
    setLoading(true);

    // conditions :
    // 1. if image changed then upload image and update data
    // 2. if image not changed then update data
    // 3. if image changed and attachment not changed then upload image and update data
    // 4. if image changed and attachment changed then upload image and attachment and update data

    if (imageChanged && attachmentChanged) {
      // upload image and attachment
      let name = getExtendedFileName(mediaUrl);
      let filename = getExtendedFileName(attachmentUrl);

      UploadImage({
        file: mediaUrl,
        name: name,
      })
        .then(() => {
          UploadImage({
            file: attachmentUrl,
            name: filename,
          })
            .then(() => {
              callApi({
                url: "updateContent/" + id,
                method: "patch",
                type: "content",
                data: {
                  ...values,
                  tags: tags,
                  mediaUrl: name,
                  attachmentUrl: filename,
                  tag: undefined,
                },
                header: true,
              }).then(() => {
                setLoading(false);
                navigate("/content");
                toast.success("Successfully Updated!");
              });
            })
            .catch(() => {
              setLoading(false);
              toast.error("Something went wrong!");
            });
        })
        .catch(() => {
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }

    if (imageChanged && !attachmentChanged) {
      // upload image and data without attachment'
      let name = getExtendedFileName(mediaUrl);

      UploadImage({
        file: mediaUrl,
        name: name,
      })
        .then(() => {
          callApi({
            url: "updateContent/" + id,
            method: "patch",
            type: "content",
            data: {
              ...values,
              tags: tags,
              mediaUrl: name,
              // attachmentUrl: "",
              tag: undefined,
            },
            header: true,
          }).then(() => {
            setLoading(false);
            toast.success("Successfully Created!");
            navigate("/content");
          });
        })
        .catch(() => {});
    }

    if (!imageChanged && attachmentUrl) {
      // upload attachment and data without image

      let filename = getExtendedFileName(attachmentUrl);

      UploadImage({
        file: attachmentUrl,
        name: filename,
      })
        .then(() => {
          callApi({
            url: "updateContent/" + id,
            method: "patch",
            type: "content",
            data: {
              ...values,
              tags: tags,
              attachmentUrl: filename,
              // attachmentUrl: "",
              tag: undefined,
            },
            header: true,
          })
            .then(() => {
              setLoading(false);
              toast.success("Successfully Created!");
              navigate("/content");
            })
            .catch(() => {
              setLoading(false);
              toast.error("Something went wrong!");
            });
        })
        .catch(() => {
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }

    if (!imageChanged && !attachmentUrl) {
      // upload data without image and attachment
      callApi({
        url: "updateContent/" + id,
        method: "patch",
        type: "content",
        data: {
          ...values,
          tags: tags,
          mediaUrl: values.mediaUrl,
          attachmentUrl: values.attachmentUrl,
          tag: undefined,
        },
        header: true,
      })
        .then((res) => {
          setLoading(false);
          navigate("/content");
          toast.success("Successfully Updated!");
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }

    // if (imageChanged) {
    //   // upload image
    // } else {
    //   // don't need to upload image
    //   callApi({
    //     url: "updateContent/" + id,
    //     method: "patch",
    //     type: "content",
    //     data: {
    //       ...values,
    //       tags: tags,
    //       mediaUrl: values.mediaUrl,
    //       attachmentUrl: values.attachmentUrl,
    //       tag: undefined,
    //     },
    //     header: true,
    //   })
    //     .then((res) => {
    //       setLoading(false);
    //       navigate("/content");
    //       toast.success("Successfully Updated!");
    //     })
    //     .catch((err) => {
    //       setLoading(false);
    //       toast.error("Something went wrong!");
    //     });
    // }
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Content>
        <ContentHead
          title="Create Content"
          showBtn1={true}
          btn1Name="create"
          btn1type="filled"
          btn1Text="Save"
          onClickHandler={handleClick}
          showBtn2={true}
          btn2Name="discard"
          btn2Text="Discard"
          btn2type="outlined"
        />
        <ContentBody>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              rowGap: 12,
              columnGap: 12,
            }}
          >
            <div style={{ gridColumn: "1/3" }}>
              <label style={{ fontWeight: 500 }} htmlFor="">
                Media
              </label>
              <div>
                {values.mediaUrl === null ? (
                  <div className={styless.select_image}>
                    +
                    <input
                      type="file"
                      name="mediaUrl"
                      accept="image"
                      onChange={onChange}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundImage: id
                        ? imageChanged
                          ? `url('${URL.createObjectURL(values.mediaUrl)}')`
                          : `url('${process.env.REACT_APP_IMAGE_LINK}/${values.mediaUrl}')`
                        : `url('${URL.createObjectURL(values.mediaUrl)}')`,
                      width: "500px",
                      aspectRatio: "16/9",
                      borderRadius: "10px",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundColor: "rgba(0,0,0,.05)",
                      position: "relative",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faClose}
                      color="white"
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        height: "20px",
                        width: "20px",
                        background: "rgba(0,0,0,1)",
                        borderRadius: "50%",
                        padding: "5px",
                        transform: "translate(40%,-40%)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setValues((prev) => {
                          return { ...prev, mediaUrl: null };
                        });
                        setImageChanged(true);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div style={{ gridColumn: "1/3" }}>
              <label style={{ fontWeight: 500 }} htmlFor="">
                Attachment
              </label>
              <br />
              <input
                type="file"
                name="attachmentUrl"
                // accept="image"
                onChange={onChange}
              />
            </div>
            <InputField
              isLabel={true}
              value={title}
              lable={"Title"}
              type={"text"}
              isRequired={false}
              placeholder={"Title"}
              name={"title"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={shortDescription}
              lable={"Short Description"}
              type={"text"}
              isRequired={false}
              placeholder={"Short Description"}
              name={"shortDescription"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={type}
              lable={"Type"}
              type={"select"}
              options={[
                { title: "Blog", value: "blog" },
                { title: "Focus", value: "focus" },
                { title: "News", value: "news" },
                { title: "Press Release", value: "pressRelease" },
                { title: "Focus Projects", value: "focusProjects" },
              ]}
              isRequired={false}
              placeholder={"Type"}
              name={"type"}
              onChange={onChange}
              accessText="title"
              accessValue={"value"}
            />
            <InputField
              isLabel={true}
              value={category}
              lable={"Category"}
              type={"text"}
              isRequired={false}
              placeholder={"Category"}
              name={"category"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <div className={styles.field_contianer}>
              <label style={{ fontWeight: 500 }} htmlFor="">
                (Press enter to add) Tags
              </label>
              <input
                type="text"
                value={tag}
                placeholder="Tags"
                onChange={onChange}
                name="tag"
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    setTags((prev: any) => [...prev, tag]);
                    setValues({ ...values, tag: "" });
                  }
                }}
              />
              <Tags tags={tags} updateTag={setTags} />
            </div>
            <InputField
              isLabel={true}
              value={visibility}
              lable={"Visibility"}
              type={"select"}
              isRequired={false}
              placeholder={"Visibility"}
              name={"visibility"}
              onChange={onChange}
              options={[
                { title: "High", value: "high" },
                { title: "Medium", value: "medium" },
                { title: "Limited", value: "limited" },
              ]}
              accessText={"title"}
              accessValue={"value"}
            />
            <div style={{ gridColumn: "1/3" }}>
              <label style={{ fontWeight: 500 }}>Description</label>
              <TextEditor content={body} handleChange={onBodyChange} />
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default CreateContent;
