import AWS from "aws-sdk";
import compressImage from "./convertImg";
import { toast } from "react-hot-toast";

const checkExtension = (filename:any) => {
  var parts = filename.split('.');
  return parts[parts.length - 1];
} 

export const checkForImg = (ext:any) => {
  if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif') {
    return true;
  } else {
    return false;
  }
}

const UploadImage = ({ file, name }: { file: any; name: any }) => {

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY_SECRET,
    region: process.env.REACT_APP_APP_REGION,
  });

  const s3 = new AWS.S3();

  return new Promise((resolve, reject)=>{
    let ext = checkExtension(name);

    if(checkForImg(ext)){
      // compress image
      compressImage(file, 0.1).then((val)=>{
        const params:any = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: name,
          Body: val,
          ACL: "public-read",
        }

        s3.putObject(params).promise().then(()=>{
          resolve(true)
        }
        ).catch(()=>{          
          toast.error("something went wrong in image upload")
          reject(false)
        })

      }).catch(()=>{
        toast.error("something went wrong in image processing")
        reject(false)
      })
      
    } else {
      // do not compress
      const params:any = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: name,
        Body: file,
        ACL: "public-read",
      }

      s3.putObject(params).promise().then(()=>{
        resolve(true)
      }
      ).catch(()=>{
        toast.error("something went wrong in image upload")
        reject(false)
      })
    }

  })
};

export default UploadImage;
