import styles from "../../Styles/content.module.css";

type props = {
	children: any;
	style?: any;
};

const ContentBody = (props: props) => {
	return (
		<div className={styles.content_body_container} style={props?.style}>
			{props.children}
		</div>
	);
};

export default ContentBody;
