import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App";
import { sidebar } from "../Utils/Data";
import { useContext } from "react";
import { SideBarContext } from "../Store/sideBar";

const Router = () => {
  const { sidebar, loading }: any = useContext(SideBarContext);

  console.log("Sidebar is : ", sidebar);

  return loading ? (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className="loader"></span>
    </div>
  ) : (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: "/",
          element: <App />,
          children: sidebar.map((val: any) => {
            let route: { path: string; element: any; children?: any } = {
              path: val.path,
              element: <val.Element />,
            };

            if (val.children) {
              route.children = [
                ...val.children.map((vall: any) => {
                  return { path: vall.path, element: <vall.Element /> };
                }),
              ];
            }
            return route;
          }),
        },
      ])}
    ></RouterProvider>
  );
};

export default Router;
