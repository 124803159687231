import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";

// interface CreateContactProps {}

const CreateContact = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    title: "",
    firstName: "",
    lastName: "",
    company: "",
    position: "",
    department: "",
    email: "",
    mobileNumber: "",
    subject: "",
    message: "",
    termsCheck: "",
  });

  const {
    title,
    firstName,
    lastName,
    company,
    position,
    department,
    email,
    mobileNumber,
    subject,
    message,
    termsCheck,
  } = values;

  const onChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    if (e.target.name === "discard") {
      setValues({
        title: "",
        firstName: "",
        lastName: "",
        company: "",
        position: "",
        department: "",
        email: "",
        mobileNumber: "",
        subject: "",
        message: "",
        termsCheck: "",
      });
      navigate("/contacts");
    } else if (e.target.name === "create") {
      callApi({
        url: "addContact",
        method: "post",
        type: "contact",
        data: values,
        header: true,
      })
        .then((_res: any) => {
          setValues({
            title: "",
            firstName: "",
            lastName: "",
            company: "",
            position: "",
            department: "",
            email: "",
            mobileNumber: "",
            subject: "",
            message: "",
            termsCheck: "",
          });
          toast.success("Successfully Created!");
        })
        .catch((_e) => {
          toast.error("Something went wrong!");
        });
    }
  };

  return (
    <>
      <Content>
        <ContentHead
          title="Create Contact"
          showBtn1={true}
          btn1Name="create"
          btn1type="filled"
          btn1Text="Create"
          onClickHandler={handleClick}
          showBtn2={true}
          btn2Name="discard"
          btn2Text="Discard"
          btn2type="outlined"
        />
        <ContentBody>
          <div
            style={{
              display: "grid",
              rowGap: 12,
              gridAutoRows: "auto",
            }}
          >
            <InputField
              isLabel={false}
              value={title}
              lable={"Title"}
              type={"text"}
              isRequired={false}
              placeholder={"Title"}
              name={"title"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={firstName}
              lable={"First Name"}
              type={"text"}
              isRequired={false}
              placeholder={"First Name"}
              name={"firstName"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={lastName}
              lable={"Last Name"}
              type={"text"}
              isRequired={false}
              placeholder={"Last Name"}
              name={"lastName"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={company}
              lable={"Company"}
              type={"text"}
              isRequired={false}
              placeholder={"Company"}
              name={"company"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={position}
              lable={"Position"}
              type={"text"}
              isRequired={false}
              placeholder={"Position"}
              name={"position"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={department}
              lable={"Department"}
              type={"text"}
              isRequired={false}
              placeholder={"Department"}
              name={"department"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={email}
              lable={"Email"}
              type={"text"}
              isRequired={false}
              placeholder={"Email"}
              name={"email"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={mobileNumber}
              lable={"Mobile Number"}
              type={"text"}
              isRequired={false}
              placeholder={"Mobile Number"}
              name={"mobileNumber"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={subject}
              lable={"Subject"}
              type={"text"}
              isRequired={false}
              placeholder={"Subject"}
              name={"subject"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={message}
              lable={"Message"}
              type={"text"}
              isRequired={false}
              placeholder={"Message"}
              name={"message"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={termsCheck}
              lable={"TermsCheck"}
              type={"select"}
              isRequired={false}
              placeholder={"TermsCheck"}
              name={"termsCheck"}
              onChange={onChange}
              options={[
                { title: "Yes", value: "true" },
                { title: "No", value: "false" },
              ]}
              accessText={"title"}
              accessValue={"value"}
            />
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default CreateContact;
