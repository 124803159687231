import { useContext, useState } from "react";
import styles from "../../Styles/Login.module.css";
import logo from "../../Assets/images/Logo.png";
import { toast } from "react-hot-toast";
import callApi from "../../Apis/callApi";
import { SideBarContext } from "../../Store/sideBar";

const Login = ({ logIn }: { logIn: any }) => {
  console.log("on login screen");
  const { updateSideBar, updateUser }: any = useContext(SideBarContext);
  const [isMailSent, setIsMailSent] = useState(false);
  const [cred, setCred] = useState({
    email: "",
    otp: "",
  });

  const [token, setToken] = useState("");

  const { email, otp } = cred;

  const handleCred = (name: string) => (e: any) => {
    const value = e.target.value;
    setCred({ ...cred, [name]: value });
  };

  const onSubmitEmail = (e: any) => {
    e.preventDefault();
    if (
      !email.match(
        "^([A-Z|a-z|0-9](.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((.){0,1}[A-Z|a-z|0-9]){2}.[a-z]{2,3}$"
      )
    ) {
      toast.error("Invalid email address!");
      return;
    }
    callApi({
      url: "login",
      method: "post",
      type: "admin",
      data: { email },
    })
      .then((res: any) => {
        if (res.data.message && res.data.verificationToken) {
          toast.success("OTP Sent!");
          setIsMailSent(true);
          setToken(res.data.verificationToken);
        }
      })
      .catch((_err) => {
        toast.error(_err.response.data.message);
      });
  };

  const onSubmitOtp = (e: any) => {
    e.preventDefault();
    if (!otp.match("^[0-9]{6,6}$")) {
      toast.error("Invalid OTP!");
      return;
    }
    callApi({
      url: `verifyOtp/${token}`,
      method: "post",
      type: "admin",
      data: { otp },
    })
      .then((res: any) => {
        toast.success(res.data.message);
        localStorage.setItem("token_AHI", res.data.session);
        updateSideBar(res.data.user.role);
        // updateSideBar("cm");
        updateUser(res.data.user);
        logIn();
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };

  return (
    <div className={styles.container}>
      <img src={logo} alt="logo" />
      <div className={styles.modal}>
        <h1>ADMIN LOGIN</h1>
        <div className={styles.form}>
          <input
            type="email"
            placeholder="Enter Email Address"
            required
            autoFocus
            onChange={handleCred("email")}
            autoComplete="on"
          />
          {isMailSent && (
            <input
              type="password"
              placeholder="Enter OTP"
              autoComplete={"off"}
              onChange={handleCred("otp")}
              required
            />
          )}
          <button
            onClick={isMailSent ? onSubmitOtp : onSubmitEmail}
            style={{
              cursor: "pointer",
            }}
          >
            {!isMailSent ? "Send OTP" : "Verify OTP"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
