import { useState ,useEffect} from "react";
import toast from "react-hot-toast";
import { useNavigate ,useParams} from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import Loading from "../../../Components/Loading";

// interface CreateAdminProps {}

const CreateAdmin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {id}=useParams();
  const [values, setValues] = useState({
    name: "",
    email: "",
    role: "",
  });

  const { name, email, role } = values;

  useEffect(() => {
    if (id) {
      getData();
      setLoading(true);
    }
  }, []);

  const getData = () => {
    callApi({
      url: "" + id,
      method: "get",
      header:true,
      type: "admin",
    })
      .then((res) => {
        console.log("update admin api called", res);

        setValues({
          name: res.data.data.name,
          email: res.data.data.email,
          role: res.data.data.role,
         
        });
        
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong while fetching data!");
        setLoading(false);
      });
  };


  const onChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    if (e.target.name === "discard") {
      setValues({
        name: "",
        email: "",
        role: "",
      });
      navigate("/admins");
    } else if (e.target.name === "create") {
      setLoading(true);
      if(id){
        updateData();
      }
      else{

      
      callApi({
        url: "createUser",
        method: "post",
        type: "admin",
        data: values,
        header: true,
      })
        .then((res: any) => {
          setValues({
            name: "",
            email: "",
            role: "",
          });
          toast.success("Successfully Created!");
          setLoading(false);
          navigate('/admins')
        })
        .catch((_e) => {
          toast.error("Something went wrong!");
          setLoading(false);
        });
      }
    }
  };

  const updateData=()=>{
    callApi({
      url: "/" + id,
      method: "patch",
      type: "admin",
      data:values,
      header: true,
    })
      .then((res) => {
        setLoading(false);
        navigate("/admins");
        toast.success("Successfully Updated!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      });
  }

  return (
    <>
      <Loading isLoading={loading} />
      <Content>
        <ContentHead
          title="Create User"
          showBtn1={true}
          btn1Name="create"
          btn1type="filled"
          btn1Text="Create"
          onClickHandler={handleClick}
          showBtn2={true}
          btn2Name="discard"
          btn2Text="Discard"
          btn2type="outlined"
        />
        <ContentBody>
          <div
            style={{
              display: "grid",
              rowGap: 12,
              gridAutoRows: "auto",
            }}
          >
            <InputField
              isLabel={false}
              value={name}
              lable={"Name"}
              type={"text"}
              isRequired={false}
              placeholder={"Name"}
              name={"name"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={email}
              lable={"Email"}
              type={"text"}
              isRequired={false}
              placeholder={"Email"}
              name={"email"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={true}
              value={role}
              lable={"Role"}
              type={"select"}
              isRequired={false}
              placeholder={"Role"}
              name={"role"}
              onChange={onChange}
              options={[
                { title: "Admin", value: "admin" },
                { title: "HR", value: "hr" },
                { title: "Manager", value: "manager" },
                { title: "CM", value: "cm" },
              ]}
              accessText={"title"}
              accessValue={"value"}
            />
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default CreateAdmin;
