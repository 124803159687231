import { Component, ReactNode, useEffect, useState } from "react";
import callApi from "../../Apis/callApi";
import WithTableView from "../../GenericPages/WithTableView";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import Loading from "../../Components/Loading";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import Content from "../../Components/Content/Content";
import DraggableTable from "../../Components/DraggableTable";
import withRouter from "../../Components/withRouter";

class Partner extends Component<any, any> {
  state = {
    partner: [],
    loading: true,
    index1: 0,
    index2: 0,
  };

  componentDidMount(): void {
    this.getData();
  }

  getData = () => {
    callApi({
      url: "getAllPartner",
      method: "get",
      type: "partner",
    })
      .then((res) => {
        this.setState({
          partner: res.data.data.partner
            .map((val: any) => ({ ...val, id: val._id }))
            .sort((a: any, b: any) => a.priority - b.priority),
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleDelete = (val: any, id: any) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `https://www.ahinternational.online/v1/partner/deletePartner/${id}`,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_AHI")}`,
          },
        })
          .then((res) => {
            this.getData();
            resolve(true);
          })
          .catch((_e) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Something went wrong!",
      }
    );
  };

  handleSwap = (id1: any, id2: any, priority1: any, priority2: any) => {
    let temp: any = [...this.state.partner];

    let index1 = temp.findIndex((val: any) => val.id === id1);

    let index2 = temp.findIndex((val: any) => val.id === id2);

    this.setState({ index1: index1, index2: index2 }, () => {
      if (priority1 > priority2) {
        temp[index1].priority = priority2;
        // means picked from bottom to the top position
        for (let i = index2; i < index1; i++) {
          temp[i].priority = temp[i].priority + 1;
        }
      }

      if (priority2 > priority1) {
        // means picked from the top to bottom position
        temp[index1].priority = priority2;

        for (let i = index1 + 1; i < index2 + 1; i++) {
          temp[i].priority = temp[i].priority - 1;
        }
      }

      this.setState(
        {
          partner: temp.sort((a: any, b: any) => a.priority - b.priority),
          loading: true,
        },
        () => {
          this.updateForSwap();
        }
      );
    });
  };

  updateForSwap = async () => {
    // console.log("in update for swap ", this.state.index1, this.state.index2);

    if (this.state.index1 > this.state.index2) {
      for (let i = this.state.index2; i < this.state.index1 + 1; i++) {
        let temp = await callApi({
          method: "patch",
          // @ts-ignore
          data: { priority: this.state.partner[i].priority },
          // @ts-ignore
          url: "updatePartner/" + this.state.partner[i].id,
          type: "partner",
          header: true,
        });

        console.log("temp - ", i);
      }

      console.log("refetching*******");

      this.getData();
    }

    if (this.state.index2 > this.state.index1) {
      for (let i = this.state.index1; i < this.state.index2 + 1; i++) {
        let temp = await callApi({
          // @ts-ignore
          url: "updatePartner/" + this.state.partner[i].id,
          // @ts-ignore
          data: { priority: this.state.partner[i].priority },
          method: "patch",
          type: "partner",
          header: true,
        });

        console.log("temp - ", i);
      }

      console.log("refetching*******");

      this.getData();
    }
  };

  render(): ReactNode {
    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Content>
          <ContentHead
            title="Partners"
            showBtn1={true}
            btn1type="filled"
            btn1Text="Add Partner"
            onClickHandler={() => this.props.router.navigate("/partner/create")}
          />
          <ContentBody>
            <>
              <DraggableTable
                head={[
                  "S.No",
                  "Partner Name",
                  "Partner City",
                  "Partner Country",
                  "",
                ]}
                body={this.state.partner}
                body_keys={[
                  "sno",
                  "partnerName",
                  "partnerCity",
                  "partnerCountry",
                  "action",
                ]}
                onSwap={this.handleSwap}
                onDelete={this.handleDelete}
                onEdit={(id: any) =>
                  this.props.router.navigate("/partner/update_partner/" + id)
                }
              />
            </>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(Partner);
