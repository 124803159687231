import { useContext, useState } from "react";
import styles from "../../Styles/applayout.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { SideBarContext } from "../../Store/sideBar";
import logo from "../../Assets/images/Logo.png";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const { sidebar }: any = useContext(SideBarContext);

  return (
    <div className={styles.sidebar_container}>
      <div
        className={styles.sidebar_main}
        style={{ width: open ? "200px" : "" }}
      >
        <div
          className={`${styles.menu_icon_container} ${
            open ? styles.open_menu_icon : ""
          }`}
          onClick={() => {
            setOpen((old) => !old);
          }}
        >
          {/* line 1 */}
          <div className={styles.menu_line_one}></div>
          {/* line 2 */}
          <div className={styles.menu_line_two}></div>
          {/* line 3 */}
          <div className={styles.menu_line_three}></div>
        </div>
        {/* <img src={logo} alt="logo" /> */}
        <ul className={styles.menu_items_container}>
          {sidebar
            .filter((el: any) => {
              return el.view;
            })
            .map((val: any, index: any) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    navigate(val.path);
                    setOpen(false);
                  }}
                  className={`${val.path === location ? styles.selected : ""}`}
                  // style={val.path === location ? { background: "blue" } : {}}
                >
                  <FontAwesomeIcon
                    icon={val.icon}
                    className={styles.icon_color}
                  />
                  {/* <i className={"fa-address-book"}></i> */}
                  <span
                    className={`${styles.sidebar_text} ${
                      open && styles.sidebar_text_show
                    }`}
                  >
                    {val.title}
                  </span>
                </li>
              );
            })}

          <li
            onClick={() => {
              localStorage.removeItem("token_AHI");
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }}
          >
            <FontAwesomeIcon icon={faRightFromBracket} />
            <span
              className={`${styles.sidebar_text} ${
                open && styles.sidebar_text_show
              }`}
            >
              Logout
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
