import React from "react";
import styles from "../../Styles/withTableView.module.css";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import Table from "../../Components/Table/Table";

type props = {
  heading: string;
  head: { title: string }[];
  body: any;
  body_keys: any;
  show_btn_1: boolean;
  btn1Text?: string;
  btn1Type?: "outlined" | "filled" | undefined;
  onClickHandler?: any;
  onActionClick?: any;
  onRowClick?: any;
  isIcon?: boolean;
  iconType?: string;
  onRemarksSave?: any;
  handleDropdownUpdate?: any;
};

function WithTableView(props: props) {
  return (
    <Content>
      <ContentHead
        title={props.heading}
        showBtn1={props.show_btn_1 ? props.show_btn_1 : false}
        btn1Text={props.btn1Text ? props.btn1Text : ""}
        btn1type={props.btn1Type ? props.btn1Type : undefined}
        onClickHandler={props?.onClickHandler ? props.onClickHandler : ""}
        isIcon={props?.isIcon}
        iconType={props?.iconType}
      />
      <ContentBody style={{ padding: 0 }}>
        <Table
          head={[...props.head]}
          body={props?.body}
          body_keys={[...props?.body_keys]}
          number={true}
          onActionClick={props.onActionClick}
          onRowClick={props.onRowClick}
          onRemarksSave={props.onRemarksSave}
          handleDropdownUpdate={props.handleDropdownUpdate}
        />
      </ContentBody>
    </Content>
  );
}

export default WithTableView;
