const Loading = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 10000,
            background: "rgba(0,0,0,.4)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Loading;
