import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../Styles/applayout.module.css";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
// import { ReactComponent as Logo } from "../../../Assets/logo.svg";

const Navbar = () => {
	return (
		<></>
		// <div className={styles.nav_container}>
		// 	<div style={{ display: "flex", alignItems: "center" }}>
		// 		{/* <Logo
		// 			style={{
		// 				height: "40px",
		// 				paddingRight: "30px",
		// 				borderRight: "1px solid black",
		// 			}}
		// 		/> */}
		// 		<span style={{ paddingLeft: "0px" }}>Admin</span>
		// 	</div>
		// 	<button
		// 		onClick={() => {
		// 			localStorage.removeItem("token");
		// 			window.location.reload();
		// 		}}
		// 	>
		// 		<FontAwesomeIcon icon={faRightFromBracket} />
		// 	</button>
		// </div>
	);
};

export default Navbar;
