import { useEffect, useState } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import { useNavigate } from "react-router-dom";
import callApi from "../../Apis/callApi";
import toast from "react-hot-toast";
import axios from "axios";
import Loading from "../../Components/Loading";

const Career = () => {
  const [career, setCareer] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const getData = () => {
    callApi({
      url: "getAllJob",
      method: "get",
      type: "career",
    })
      .then((res) => {
        setCareer(res.data.data.job);
        setLoading(false);
      })
      .catch((_e) => {
        toast.error("Something went wrong!");
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (val: any, id: any) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `https://www.ahinternational.online/v1/career/deleteJob/${id}`,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_AHI")}`,
          },
        })
          .then((res) => {
            getData();
            resolve(true);
          })
          .catch((_e) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Something went wrong!",
      }
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <WithTableView
        heading="Career"
        head={[
          { title: "S.No." },
          { title: "Department" },
          { title: "Position" },
          { title: "Type" },
          { title: "Title" },
          { title: "Opening" },
          { title: "" },
        ]}
        body={career.map((el: any) => {
          return {
            _id: el._id,
            Department: el.department,
            Position: el.position,
            Type: el.type,
            Title: el.title,
            Opening: el.numberOpening,
          };
        })}
        body_keys={[
          "Department",
          "Position",
          "Type",
          "Title",
          "Opening",
          "action:delete",
        ]}
        show_btn_1={true}
        btn1Text="Add new"
        btn1Type="filled"
        onRowClick={(val: any) => {
          navigate(`/career/update_career/${val._id}`);
        }}
        onClickHandler={() => {
          navigate("/career/create");
        }}
        onActionClick={handleDelete}
      />
    </>
  );
};

export default Career;
