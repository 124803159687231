import Button from "../../Components/Button";
import styles from "../../Styles/inputField.module.css";

interface SocialProps {
  data: any;
  index: number;
  remove: any;
  updateName: any;
  updateTag: any;
}

const Social = ({ data, index, remove, updateName }: SocialProps) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 8,
          border: "1px solid black",
          padding: "12px 8px",
          borderRadius: 8,
        }}
      >
        <Button
          text={"Remove"}
          type={"outlined"}
          name={"remove"}
          clickHandler={remove}
          isIcon=""
          style={{
            padding: "4px 12px",
          }}
        />
        <div className={styles.field_contianer}>
          <label htmlFor="">Name</label>
          <input
            type="text"
            value={data.name}
            placeholder="Name"
            onChange={(e) => updateName(e, index, "name")}
            name="name"
          />
        </div>
        <div className={styles.field_contianer}>
          <label htmlFor="">URL</label>
          <input
            type="text"
            value={data.url}
            placeholder="Url"
            onChange={(e) => updateName(e, index, "url")}
            name="url"
          />
        </div>
      </div>
    </>
  );
};

export default Social;
