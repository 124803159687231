import ReactDOM from "react-dom/client";
import "./index.css";
import { Toaster } from "react-hot-toast";
import { SideBarProvider } from "./Store/sideBar";
import Router from "./App/Router";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <SideBarProvider>
      <Toaster />
      <Router />
    </SideBarProvider>
  </>
);
