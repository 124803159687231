import { checkForImg } from "./UploadImage";

export const getExtendedFileName = (file: any) => {
  const time = new Date();
  const fileName =
    file.name?.substring(0, file.name.lastIndexOf(".")) || file.name;
  let newName = `${fileName}_${time.getHours()}${time.getMinutes()}${time.getSeconds()}${time.getMilliseconds()}`;

  if(checkForImg(file.name.split(".")[1])){
    newName += ".webp";
    return newName;
  }

  newName += `.${file.name.split(".")[1]}`;
  return newName;
};
