import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import Button from "../../../Components/Button";
import Social from "../Social";
import styles from "../../../Styles/inputField.module.css";
import UploadImage from "../../../Utils/UploadImage";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import Loading from "../../../Components/Loading";

// interface CreateCompanyProps {}

const CreateCompany = () => {
  const navigate = useNavigate();
  // const {id} =useParams();
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    date: "",
    companyName: "",
    compantLogo: "",
    address: "",
    city: "",
    country: "",
    pincode: "",
    landline: "",
    mobile: "",
    emailAdd1: "",
    emailAdd2: "",
  });

  const {
    date,
    companyName,
    compantLogo,
    address,
    city,
    country,
    pincode,
    landline,
    mobile,
    emailAdd1,
    emailAdd2,
  } = values;

  const [social, setSocial] = useState([{ name: "", url: "" }]);

  const handleAdd = () => {
    setSocial((prev: any) => {
      prev.push({ name: "", url: "" });
      return [...prev];
    });
  };

  const updateName = (e: any, index: number) => {
    setSocial((prev: any) => {
      prev[index][e.target.name] = e.target.value;
      return [...prev];
    });
  };

  const handleRemove = (index: number) => {
    setSocial((prev: any) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const onChange = (e: any) => {
    let name = e.target.name;
    let value = name === "compantLogo" ? e.target.files[0] : e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleClick = (e: any) => {
    e.preventDefault();

    if (e.target.name === "discard") {
      setValues({
        date: "",
        companyName: "",
        compantLogo: "",
        address: "",
        city: "",
        country: "",
        pincode: "",
        landline: "",
        mobile: "",
        emailAdd1: "",
        emailAdd2: "",
      });
      navigate("/company-data");
    } else if (e.target.name === "create") {
      setLoading(true);

      let compLogo = getExtendedFileName(compantLogo);

      UploadImage({ file: compantLogo, name: compLogo })
        .then(() => {
          callApi({
            url: "addCompany",
            method: "post",
            type: "company",
            data: {
              ...values,
              socialMedia: social,
              compantLogo: compLogo,
            },
            header: true,
          })
            .then((_res: any) => {
              setValues({
                date: "",
                companyName: "",
                compantLogo: "",
                address: "",
                city: "",
                country: "",
                pincode: "",
                landline: "",
                mobile: "",
                emailAdd1: "",
                emailAdd2: "",
              });

              toast.success("Successfully Created!");
              setLoading(false);
            })
            .catch((_e) => {
              toast.error("Something went wrong!");
              setLoading(false);
            });
        })
        .catch((_e) => {
          toast.error("Something went wrong!");
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Content>
        <ContentHead
          title="Create Company Data"
          showBtn1={true}
          btn1Name="create"
          btn1type="filled"
          btn1Text="Create"
          onClickHandler={handleClick}
          showBtn2={true}
          btn2Name="discard"
          btn2Text="Discard"
          btn2type="outlined"
        />
        <ContentBody>
          <div
            style={{
              display: "grid",
              rowGap: 12,
              gridAutoRows: "auto",
            }}
          >
            <InputField
              isLabel={false}
              value={date}
              lable={"Date"}
              type={"date"}
              isRequired={false}
              placeholder={"Date"}
              name={"date"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={companyName}
              lable={"Company Name"}
              type={"text"}
              isRequired={false}
              placeholder={"Company Name"}
              name={"companyName"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <div className={styles.field_contianer}>
              <label style={{ fontWeight: 500 }} htmlFor="">
                Company Logo
              </label>
              <input
                type="file"
                name="compantLogo"
                accept="image"
                onChange={onChange}
              />
            </div>
            <InputField
              isLabel={false}
              value={address}
              lable={"Address"}
              type={"text"}
              isRequired={false}
              placeholder={"Address"}
              name={"address"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={city}
              lable={"City"}
              type={"text"}
              isRequired={false}
              placeholder={"City"}
              name={"city"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={country}
              lable={"Country"}
              type={"text"}
              isRequired={false}
              placeholder={"Country"}
              name={"country"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={pincode}
              lable={"Pincode"}
              type={"text"}
              isRequired={false}
              placeholder={"Pincode"}
              name={"pincode"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={landline}
              lable={"Landline"}
              type={"text"}
              isRequired={false}
              placeholder={"Landline"}
              name={"landline"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={mobile}
              lable={"Mobile"}
              type={"text"}
              isRequired={false}
              placeholder={"Mobile"}
              name={"mobile"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={emailAdd1}
              lable={"Email Address 1"}
              type={"text"}
              isRequired={false}
              placeholder={"Email Address 1"}
              name={"emailAdd1"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <InputField
              isLabel={false}
              value={emailAdd2}
              lable={"Email Address 2"}
              type={"text"}
              isRequired={false}
              placeholder={"Email Address 2"}
              name={"emailAdd2"}
              onChange={onChange}
              options={""}
              accessText={""}
              accessValue={""}
            />
            <label
              htmlFor=""
              style={{
                fontWeight: 500,
              }}
            >
              Social Media
            </label>
            <Button
              text={"Add More"}
              type={"outlined"}
              name={"add"}
              clickHandler={handleAdd}
              isIcon=""
              style={{
                padding: "4px 12px",
                marginBottom: 20,
              }}
            />
            <div
              style={{
                display: "grid",
                gridAutoFlow: "row",
                rowGap: 28,
              }}
            >
              {social.map((item: any, index: number) => {
                return (
                  <Social
                    data={item}
                    index={index}
                    remove={handleRemove}
                    updateName={(e: any) => updateName(e, index)}
                    updateTag={setSocial}
                  />
                );
              })}
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default CreateCompany;
