import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import styles from "../../../Styles/inputField.module.css";
import UploadImage from "../../../Utils/UploadImage";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import Loading from "../../../Components/Loading";
import CreateTeam from "../createTeam/Index";


// interface UpdateTeamProps {}

const UpdateTeam = () => {
  // const navigate = useNavigate();
  // const { id } = useParams();
  // const [loading, setLoading] = useState(false);

  // const [values, setValues] = useState({
  //   employeeId: "",
  //   name: "",
  //   department: "",
  //   position: "",
  //   joiningDate: "",
  //   image: "",
  //   location: "",
  //   linkedin: "",
  //   bio: "",
  // });

  // useEffect(() => {
  //   if (id) {
  //     getData();
  //   }
  // }, []);

  // const getData = () => {
  //   callApi({
  //     url: "getTeamById/" + id,
  //     method: "get",
  //     type: "team",
  //   })
  //     .then((res) => {
  //       console.log("update client api called", res);
  //       let image=`${process.env.REACT_APP_IMAGE_LINK}/${res.data.data.image}`
  //       setValues({
  //         employeeId: res.data.data.employeeId,
  //         name: res.data.data.name,
  //         department: res.data.data.department,
  //         position: res.data.data.position,
  //         joiningDate: res.data.data.joiningDate,
  //         image: image,
  //         location: res.data.data.location,
  //         linkedin: res.data.data.linkedin,
  //         bio: res.data.data.bio,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Something went wrong while fetching data!");
  //     });
  // };

  // const {
  //   employeeId,
  //   name,
  //   department,
  //   position,
  //   joiningDate,
  //   image,
  //   location,
  //   linkedin,
  //   bio,
  // } = values;

  // const onChange = (e: any) => {
  //   let name = e.target.name;
  //   let value = name === "image" ? e.target.files[0] : e.target.value;
  //   setValues({ ...values, [name]: value });
  // };

  // const handleClick = (e: any) => {
  //   e.preventDefault();
  //   if (e.target.name === "discard") {
  //     setValues({
  //       employeeId: "",
  //       name: "",
  //       department: "",
  //       position: "",
  //       joiningDate: "",
  //       image: "",
  //       location: "",
  //       linkedin: "",
  //       bio: "",
  //     });
  //     navigate("/teams");
  //   } else if (e.target.name === "update") {
  //     UploadImage({ file: image, name: getExtendedFileName(image) })
  //       .then(() => {
  //         callApi({
  //           url: `updateTeam/${id}`,
  //           method: "patch",
  //           type: "team",
  //           data: { ...values, image: getExtendedFileName(image) },
  //           header: true,
  //         })
  //           .then((res: any) => {
  //             setValues({
  //               employeeId: "",
  //               name: "",
  //               department: "",
  //               position: "",
  //               joiningDate: "",
  //               image: "",
  //               location: "",
  //               linkedin: "",
  //               bio: "",
  //             });
  //             toast.success("Successfully Updated!");
  //           })
  //           .catch((_e) => {
  //             toast.error("Something went wrong!");
  //           });
  //       })
  //       .catch((e) => toast.error("Something went wrong!"));
  //   }
  // };

  // return (
  //   <>
  //     <Loading isLoading={loading} />
  //     <Content>
  //       <ContentHead
  //         title="Update Team"
  //         showBtn1={true}
  //         btn1Name="update"
  //         btn1type="filled"
  //         btn1Text="Update"
  //         onClickHandler={handleClick}
  //         showBtn2={true}
  //         btn2Name="discard"
  //         btn2Text="Discard"
  //         btn2type="outlined"
  //       />
  //       <ContentBody>
  //         <div
  //           style={{
  //             display: "grid",
  //             rowGap: 12,
  //             gridAutoRows: "auto",
  //           }}
  //         >
  //           <InputField
  //             isLabel={false}
  //             value={employeeId}
  //             lable={"Employee Id"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Employee Id"}
  //             name={"employeeId"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={name}
  //             lable={"Name"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Name"}
  //             name={"name"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={department}
  //             lable={"Department"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Department"}
  //             name={"department"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={position}
  //             lable={"Position"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Position"}
  //             name={"position"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={true}
  //             value={joiningDate}
  //             lable={"Joining Date"}
  //             type={"date"}
  //             isRequired={false}
  //             placeholder={"Joining Date"}
  //             name={"joiningDate"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <div className={styles.field_contianer}>
  //             <label style={{ fontWeight: 500 }} htmlFor="">
  //               Image
  //             </label>
  //             <input
  //               type="file"
  //               name="image"
  //               accept="image"
  //               onChange={onChange}
  //             />
  //           </div>
  //           <InputField
  //             isLabel={false}
  //             value={location}
  //             lable={"Location"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Location"}
  //             name={"location"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={linkedin}
  //             lable={"Linkedin"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Linkedin"}
  //             name={"linkedin"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={bio}
  //             lable={"Bio"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Bio"}
  //             name={"bio"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //         </div>
  //       </ContentBody>
  //     </Content>
    // </>
  // );

    return <CreateTeam/>;

};

export default UpdateTeam;
