import axios from "axios";

type props = {
  url: string;
  method: string;
  header?: boolean;
  data?: any;
  type:
    | "admin"
    | "client"
    | "content"
    | "career"
    | "partner"
    | "hero"
    | "team"
    | "contact"
    | "solution"
    | "company";
};

let url = "https://www.ahinternational.online/v1/";

const callApi = (props: props) => {
  let data = props.data;

  return axios({
    url: url + props.type + "/" + props.url,
    method: props.method,
    data: data,
    headers: props?.header
      ? { Authorization: `Bearer ${localStorage.getItem("token_AHI")}` }
      : {},
  });
};

export default callApi;
