import { useState,useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../../Apis/callApi";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import InputField from "../../../Components/InputField";
import Content from "../../../Components/Content/Content";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import UploadImage from "../../../Utils/UploadImage";
import Loading from "../../../Components/Loading";
import CreatePartner from "../createPartner/Index";

// interface UpdatePartnerProps {}

const UpdatePartner = () => {
  // const navigate = useNavigate();
  // const { id } = useParams();
  // const [loading, setLoading] = useState(false);

  // const [values, setValues] = useState({
  //   partnerName: "",
  //   partnerCity: "",
  //   partnerCountry: "",
  //   description: "",
  //   solution: "",
  //   logoUrl: "",
  //   imageOne: "",
  //   // priority: "",
  // });

  // useEffect(() => {
  //   if (id) {
  //     getData();
  //   }
  // }, []);

  // const getData = () => {
  //   callApi({
  //     url: "getPartnerById/" + id,
  //     method: "get",
  //     type: "partner",
  //   })
  //     .then((res) => {
  //       console.log('update client api called',res);
  //       setValues({
  //         partnerName: res.data.data.partnerName,
  //         partnerCity: res.data.data.partnerCity,
  //         partnerCountry: res.data.data.partnerCountry,
  //         description: res.data.data.description,
  //         solution: res.data.data.solution,
  //         logoUrl: '',
  //         imageOne: '',
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Something went wrong while fetching data!");
  //     });
  // };


  // const {
  //   partnerName,
  //   partnerCity,
  //   partnerCountry,
  //   description,
  //   solution,
  //   logoUrl,
  //   imageOne,
  //   // priority,
  // } = values;

  // const onChange = (e: any) => {
  //   let name = e.target.name;
  //   let value =
  //     name === "logoUrl" || name === "imageOne"
  //       ? e.target.files[0]
  //       : e.target.value;
  //   setValues({ ...values, [name]: value });
  // };

  // const handleClick = (e: any) => {
  //   e.preventDefault();
  //   if (e.target.name === "discard") {
  //     setValues({
  //       partnerName: "",
  //       partnerCity: "",
  //       partnerCountry: "",
  //       description: "",
  //       solution: "",
  //       logoUrl: "",
  //       imageOne: "",
  //       // priority: "",
  //     });
  //     navigate("/partner");
  //   } else if (e.target.name === "update") {
  //     UploadImage({ file: logoUrl, name: getExtendedFileName(logoUrl) })
  //       .then(() => {
  //         UploadImage({ file: imageOne, name: getExtendedFileName(imageOne) })
  //           .then(() => {
  //             callApi({
  //               url: `updatePartner/${id}`,
  //               method: "patch",
  //               type: "partner",
  //               data: values,
  //               header: true,
  //             })
  //               .then((_res: any) => {
  //                 setValues({
  //                   partnerName: "",
  //                   partnerCity: "",
  //                   partnerCountry: "",
  //                   description: "",
  //                   solution: "",
  //                   logoUrl: "",
  //                   imageOne: "",
  //                   // priority: "",
  //                 });
  //                 toast.success("Successfully Updated!");
  //               })
  //               .catch((_e) => {
  //                 toast.error("Something went wrong!");
  //               });
  //           })
  //           .catch((_e) => {
  //             toast.error("Something went wrong!");
  //           });
  //       })
  //       .catch((_e) => {
  //         toast.error("Something went wrong!");
  //       });
  //   }
  // };

  // return (
  //   <>
  //     <Loading isLoading={loading} />
  //     <Content>
  //       <ContentHead
  //         title="Update Partner"
  //         showBtn1={true}
  //         btn1Name="update"
  //         btn1type="filled"
  //         btn1Text="Update"
  //         onClickHandler={handleClick}
  //         showBtn2={true}
  //         btn2Name="discard"
  //         btn2Text="Discard"
  //         btn2type="outlined"
  //       />
  //       <ContentBody>
  //         <div
  //           style={{
  //             display: "grid",
  //             rowGap: 12,
  //             gridAutoRows: "auto",
  //           }}
  //         >
  //           <InputField
  //             isLabel={false}
  //             value={partnerName}
  //             lable={"Partner Name"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Partner Name"}
  //             name={"partnerName"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={partnerCity}
  //             lable={"Partner City"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Partner City"}
  //             name={"partnerCity"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={partnerCountry}
  //             lable={"Partner Country"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Partner Country"}
  //             name={"partnerCountry"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={description}
  //             lable={"Description"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Description"}
  //             name={"description"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={false}
  //             value={solution}
  //             lable={"Solution"}
  //             type={"text"}
  //             isRequired={false}
  //             placeholder={"Solution"}
  //             name={"solution"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           {/* <InputField
  //             isLabel={true}
  //             value={priority}
  //             lable={"Priority"}
  //             type={"select"}
  //             isRequired={false}
  //             placeholder={"Priority"}
  //             name={"priority"}
  //             onChange={onChange}
  //             options={[
  //               { title: "High", value: "high" },
  //               { title: "Medium", value: "medium" },
  //               { title: "Low", value: "low" },
  //             ]}
  //             accessText={"title"}
  //             accessValue={"value"}
  //           /> */}
  //           <InputField
  //             isLabel={true}
  //             value={logoUrl}
  //             lable={"Logo"}
  //             type={"file"}
  //             isRequired={false}
  //             placeholder={"Logo Url"}
  //             name={"logoUrl"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //           <InputField
  //             isLabel={true}
  //             value={imageOne}
  //             lable={"Image"}
  //             type={"file"}
  //             isRequired={false}
  //             placeholder={"Image One"}
  //             name={"imageOne"}
  //             onChange={onChange}
  //             options={""}
  //             accessText={""}
  //             accessValue={""}
  //           />
  //         </div>
  //       </ContentBody>
  //     </Content>
  //   </>
  // );

  return <CreatePartner/>
};

export default UpdatePartner;
